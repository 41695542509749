import GoodsAddDialog from './dialog/goodsAddDialog';
export default {
  name: 'GoodsIndex',
  components: {
    GoodsAddDialog
  },
  data() {
    return {
      module: 'goods',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: true,
        multiDel: false
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$store.dispatch('cleanColumnOptions', this.module);
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.goodsAddDialog.open(row);
    },
    onAdd() {
      this.$refs.goodsAddDialog.open({});
    }
  }
};