export default {
  name: 'UserMsgAddDialog',
  data() {
    return {
      module: 'userMsg',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      is_read,
      userMsgContent = {}
    }) {
      this.form = {
        user_id: {
          label: '用户',
          value: user_id,
          formType: 'remote',
          options: '/user/select',
          attrs: {
            multiple: !id
          }
        },
        content: {
          label: '内容',
          value: userMsgContent.content,
          formType: 'textarea',
          max: 500,
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};