export default {
  name: 'OrderGoodsIndex',
  data() {
    return {
      module: 'orderGoods',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {},
    onAdd() {}
  }
};