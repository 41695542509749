export default {
  name: 'UserWithdrawAddDialog',
  data() {
    return {
      module: 'userWithdraw',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      amount,
      status,
      account_type,
      account,
      reason,
      verify_admin_id,
      pay_admin_id,
      verify_at,
      paid_at
    }) {
      this.form = {
        user_id: {
          label: '',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        amount: {
          label: '提现金额',
          value: amount,
          formType: 'number'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        account_type: {
          label: '账号类型',
          value: account_type,
          formType: 'select',
          options: {
            url: '/userWithdraw/create',
            key: 'accountType'
          }
        },
        account: {
          label: '提现账号',
          value: account
        },
        reason: {
          label: '失败原因',
          value: reason
        },
        verify_admin_id: {
          label: '审核人',
          value: verify_admin_id,
          formType: 'select',
          options: '/verifyAdmin/select'
        },
        pay_admin_id: {
          label: '支付人',
          value: pay_admin_id,
          formType: 'select',
          options: '/payAdmin/select'
        },
        verify_at: {
          label: '审核时间',
          value: verify_at
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};