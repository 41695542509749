import { getLodop } from './LodopFuncs';
export default {
  name: "PrintRecipt",
  data() {
    return {
      vendorId: " ",
      //打印机的 vendorId
      device: null,
      // 设备
      CreatedOKLodop7766: null
    };
  },
  methods: {
    /**
     * 打印小票
     */
    async printReceipt1() {
      try {
        // 判断device是否是同一个
        var storageDevice = localStorage.getItem("device");
        // 查找连接的设备
        if (!this.device || storageDevice != this.device) {
          // 设备不存在 和设备不一致 重新选择设备
          navigator.usb.getDevices().then(devices => {
            devices.forEach(device => {
              this.vendorId = device.vendorId;
            });
          });
          // 请求打印机设备
          this.device = await navigator.usb.requestDevice({
            filters: [{
              vendorId: this.vendorId
            }]
          });
          // 将数据存储到storage里面
          localStorage.setItem("device", this.device);
        }
        await this.device.open(); // 打开设备

        // 选择配置和接口
        await this.device.selectConfiguration(1);
        await this.device.claimInterface(0);

        // 打印数据 2.0
        const doc = document.getElementById("printRecipt");
        const receiptData = doc.innerText;
        // 将数据编码为字节
        const byteData = new TextEncoder().encoder(receiptData);

        // 发送打印命令
        await this.device.transferOut(1, byteData);

        // 关闭打设备
        await this.device.close();
      } catch (error) {
        alert("打印失败", error);
      }
    },
    /**
     * 打印小票 c-lodop
     */
    printReceipt() {
      var myDate = new Date();
      const printContent = document.querySelector(".printRecipt").innerHTML;
      // const print_style = document.querySelector('.printRecipt').innerHTML
      const print_style = "";
      const style_innerHTML = `<style>${print_style}</style>`;
      const printHtml = `${style_innerHTML}<body>${printContent}</body>`;
      const LODOP = getLodop();
      if (LODOP != null && typeof LODOP.VERSION != "undefined") {
        LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true); // 设置打印模式（以纸张边缘为基点）
        LODOP.PRINT_INIT("");
        LODOP.SET_PREVIEW_WINDOW(1, 0, 1, 900, 500, ""); // 设置预览窗口
        LODOP.SET_PRINT_PAGESIZE(1, "60mm", "30mm", ''); //设置纸张大小
        // LODOP.SET_PRINT_STYLE("FontSize", 13) // 设置打印风格
        // LODOP.SET_PRINT_STYLE("Bold",1)
        LODOP.ADD_PRINT_HTML(2, 5, "100", "100%", printHtml); // 增加超文本项
        // LODOP.ADD_PRINT_TEXT(2,55,550,50,'');
        // LODOP.SET_PRINT_STYLEA(2,"FontSize",20);//设置上面这个条码下方的文字字体大小
        // LODOP.ADD_PRINT_BARCODE(45,5,200,45,"128A",'');
        //   LODOP.SET_PRINT_STYLEA(3,"Bold",1);
        // LODOP.SET_PRINT_STYLEA(3,"FontSize",8);//设置上面这个条码下方的文字字体大小
        // LODOP.ADD_PRINT_TEXT(95,5,210,20,myDate.toLocaleString('zh-cn'));//.toLocaleDateString() myDate.getFullYear()+'-'+myDate.getMonth()+'-'+myDate.getDate()
        // LODOP.SET_PRINT_STYLEA(4,"FontSize",10);  
        // LODOP.ADD_PRINT_TEXT(95,150,210,20,"(KG)");
        // LODOP.SET_PRINT_STYLEA(5,"FontSize",10); 
        // LODOP.ADD_PRINT_TEXT(2,125,550,50,'');
        // LODOP.SET_PRINT_STYLEA(6,"FontSize",20);
        LODOP.SET_PRINTER_INDEX("Gprinter  GP-9025T"); //指定打印设备
        /*LODOP.SET_LICENSES("","6D9E9FBA2ADB565938C033845BF5A9EF","C94CEE276DB2187AE6B65D56B3FC2848","4154EF517CB1509D9291CA6610B5CF0B");*/ //
        LODOP.SET_LICENSES("", "39AF053E4C000375C1DBE7C83E94CDDB", "C94CEE276DB2187AE6B65D56B3FC2848", ""); //设置软件产品注册信息
        LODOP.PRINT(); // 直接打印
      } else {
        alert("CLodop云打印服务未安装或启动失败");
      }
    }
  }
};