export default {
  name: 'UserCouponAddDialog',
  data() {
    return {
      module: 'userCoupon',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      title,
      value,
      max_value,
      coupon_id,
      user_id,
      min_order_amount,
      coupon_type,
      use_type,
      get_type,
      admin_id,
      order_id,
      order_pay_id,
      order_combine_id,
      use_start,
      use_end,
      currency,
      site_id,
      is_used,
      used_at
    }) {
      this.form = {
        title: {
          label: '代金券名称',
          value: title
        },
        value: {
          label: '代金券金额',
          value: value,
          formType: 'number'
        },
        max_value: {
          label: '最大优惠金额',
          value: max_value,
          formType: 'number',
          tooltip: '打折时，最大优惠金额',
          show: m => m.coupon_type === 1
        },
        coupon_id: {
          label: '',
          value: coupon_id,
          formType: 'select',
          options: '/coupon/select'
        },
        user_id: {
          label: '',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        min_order_amount: {
          label: '订单满多少可以使用',
          value: min_order_amount,
          formType: 'number'
        },
        coupon_type: {
          label: '代金券类型',
          value: coupon_type,
          formType: 'select',
          options: {
            url: '/userCoupon/create',
            key: 'couponType'
          }
        },
        use_type: {
          label: '使用范围',
          value: use_type,
          formType: 'select',
          options: {
            url: '/userCoupon/create',
            key: 'useType'
          }
        },
        get_type: {
          label: '获取类型',
          value: get_type,
          formType: 'select',
          options: {
            url: '/userCoupon/create',
            key: 'getType'
          }
        },
        admin_id: {
          label: '赠送的管理员',
          value: admin_id,
          formType: 'select',
          options: '/admin/select'
        },
        order_id: {
          label: '使用的订单',
          value: order_id,
          formType: 'select',
          options: '/order/select'
        },
        order_pay_id: {
          label: '使用的支付单',
          value: order_pay_id,
          formType: 'select',
          options: '/orderPay/select'
        },
        order_combine_id: {
          label: '使用的国际发货单',
          value: order_combine_id,
          formType: 'select',
          options: '/orderCombine/select'
        },
        use_start: {
          label: '使用开始时间',
          value: use_start
        },
        use_end: {
          label: '使用结束时间',
          value: use_end
        },
        currency: {
          label: '货币类型',
          value: currency,
          formType: 'select',
          options: '/siteConfig/currency'
        },
        site_id: {
          label: '适用站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select'
        },
        used_at: {
          label: '使用时间',
          value: used_at
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};