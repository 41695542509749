import AssignDialog from './dialog/assignDialog';
export default {
  components: {
    AssignDialog
  },
  created() {
    this.work.id = this.$route.query.id;
    this.getList();
  },
  data() {
    return {
      module: 'work',
      work: {
        id: undefined
      },
      contents: [],
      formWork: {},
      form: {},
      loading: false
    };
  },
  methods: {
    getList() {
      this.$w_fun.post(`${this.module}/get`, {
        id: this.work.id
      }).then(res => {
        this.work = res.data.detail;
        this.contents = this.work.contents;
        this.setFormWork();
      });
    },
    setFormWork() {
      this.formWork = {
        desc: {
          label: '简要描述',
          value: this.work.desc,
          width: '100%'
        },
        status: {
          label: '状态',
          value: this.work.status_text
        },
        created_at: {
          label: '提出时间',
          value: this.work.created_at
        },
        accepted_at: {
          label: '接单时间',
          value: this.work.accepted_at
        },
        done_at: {
          label: '完成时间',
          value: this.work.done_at
        },
        add_admin_id: {
          label: '提出人',
          value: this.work.add_admin_id,
          formType: 'select',
          options: '/admin/select',
          replace: true
        },
        handle_admin_id: {
          label: '处理人',
          value: this.work.handle_admin_id,
          formType: 'select',
          options: '/admin/select',
          replace: true
        },
        work_type_id: {
          label: '工单类型',
          value: this.work.work_type_text
        },
        priority_id: {
          label: '优先级',
          value: this.work.priority_text
        }
      };
      if (this.work.status === 1) {
        this.form = {
          id: {
            show: false,
            value: this.work.id
          },
          content: {
            label: '回复内容',
            value: '',
            formType: 'textarea',
            max: 1000,
            attrs: {
              autosize: {
                minRows: 4,
                maxRows: 8
              }
            }
          },
          files: {
            label: '附件',
            value: [],
            formType: 'upload',
            attrs: {
              drag: true,
              accept: '.jpg,.png,.jpeg',
              limit: 20
            },
            type: 'array',
            required: false
          }
        };
      }
    },
    reply() {
      this.loading = true;
      try {
        this.loading = true;
        this.$refs.wForm.submit().then(res => {
          this.loading = false;
          this.getList();
        });
      } catch (e) {
        this.loading = false;
      }
    },
    assign() {
      this.$refs.assignDialog.open([this.work.id]);
    }
  }
};