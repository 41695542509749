import UserCouponAddDialog from './dialog/userCouponAddDialog';
export default {
  name: 'UserCouponIndex',
  components: {
    UserCouponAddDialog
  },
  data() {
    return {
      module: 'userCoupon',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$message.info('开发中');
      return;
      this.$refs.userCouponAddDialog.open(row);
    },
    onAdd() {
      this.$message.info('开发中');
      return;
      this.$refs.userCouponAddDialog.open({});
    }
  }
};