import { debounce } from 'lodash';
import PrintRecipt from '@/components/Print/PrintRecipt';
import dayjs from 'dayjs';
export default {
  name: 'StoreIndex',
  components: {
    PrintRecipt
  },
  computed: {
    size_() {
      return this.$store.getters['size'];
    },
    canAdd() {
      const form = this.getForm();
      return form ? form.model.weight && form.model.package_no : false;
    }
  },
  mounted() {
    // window.addEventListener('keydown', this.handleKeyDown)
    this.$refs.tracking_no.focus();
  },
  beforeDestroy() {
    // window.removeEventListener('keydown', this.handleKeyDown)
  },
  watch: {
    'form.address_code_like': debounce(function (v) {
      this.search();
    }, 400),
    'form.tracking_no_like': debounce(function (v) {
      this.search();
    }, 400),
    'form.user_id_match': debounce(function (v) {
      this.search();
    }, 400),
    'form.order_no_like': debounce(function (v) {
      this.search();
    }, 400),
    'form.third_order_no_like': debounce(function (v) {
      this.search();
    }, 400)
  },
  data() {
    return {
      module: 'store',
      model: {
        id: {
          show: false,
          value: undefined
        },
        package_no: {
          label: '包裹号',
          value: undefined,
          attrs: {
            disabled: true
          }
        },
        size_type: {
          label: '包裹大小',
          value: undefined,
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: undefined,
          formType: 'number',
          attrs: {
            append: 'kg'
          }
        },
        order_package_tags: {
          label: '标签',
          value: [],
          formType: 'checkbox',
          options: '/store/packageTag',
          attrs: {
            isButton: true
          },
          required: false
        },
        remark: {
          label: '备注',
          value: '',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          required: false
        },
        photos: {
          label: '图片',
          value: [],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            limit: 20
          },
          type: 'array',
          required: false
        }
      },
      form: {
        address_code_like: undefined,
        tracking_no_like: undefined,
        user_id_match: undefined,
        order_no_like: undefined,
        third_order_no_like: undefined
      },
      list: [],
      loading: false,
      lastInputTime: undefined,
      inputStr: '',
      focus: '',
      user_id: undefined,
      packageForm: {},
      formAction: 'orderPackage/edit'
    };
  },
  methods: {
    dayjs,
    search() {
      this.clearForm();
      if (!this.form.address_code_like && !this.form.tracking_no_like && !this.form.user_id_match && !this.form.order_no_like && !this.form.third_order_no_like) {
        this.list = [];
        return;
      }
      this.$w_fun.post(`${this.module}/index`, this.form).then(res => {
        this.list = res.data.list;
        if (this.list.length === 1) {
          this.$refs.table.setCurrentRow(this.list[0]);
          this.handleSelectionChange(this.list[0]);
        }
      });
    },
    handleKeyDown(v) {
      let timer;
      if (this.lastInputTime && Date.now() - this.lastInputTime < 300) {
        this.inputStr += v.key;
        timer && clearTimeout(timer);
      } else {
        this.inputStr = v.key;
        timer && clearTimeout(timer);
      }
      this.lastInputTime = Date.now();
      timer = setTimeout(() => {
        if (this.inputStr.startsWith('xxxx')) {
          this.$refs.tracking_no.focus();
          this.form.tracking_no_like = this.inputStr.substring(4);
          this.search();
        }
        if (this.inputStr.startsWith('yyyy')) {
          const n = Number(this.inputStr.substring(4));
          if (!isNaN(n)) {
            this.getForm().model.weight = n;
          }
        }
      }, 1200);
    },
    clearForm() {
      this.user_id = 0;
      const model = this.getForm().model;
      model.id = undefined;
      model.package_no = '';
      // model.weight = undefined
      model.order_package_tags = [];
      model.remark = '';
      model.photos = [];
    },
    handleSelectionChange(v) {
      if (!v) {
        this.clearForm();
        return;
      }
      this.user_id = v.user_id;
      const u = v.user_id + '';
      this.getForm().model.id = v.id;
      this.getForm().model.package_no = this.fillZero(v.id + '', 8) + this.fillZero(u.substring(u.length - 2));
      this.getForm().model.remark = v.remark;
      this.getForm().$el[3].focus();
    },
    async add() {
      try {
        this.loading = true;
        await this.getForm().submit();
        await this.printPackage();
        this.loading = false;
        setTimeout(() => {
          this.clearForm();
          this.form = {
            address_code_like: undefined,
            tracking_no_like: undefined,
            user_id_match: undefined,
            order_no_like: undefined,
            third_order_no_like: undefined
          };
          this.list = [];
          this.$refs.tracking_no.focus();
        }, 1000);
      } catch (e) {
        this.loading = false;
      }
    },
    edit(row) {
      this.packageForm = {
        id: {
          show: false,
          value: row.id
        },
        status: {
          label: '状态',
          value: row.status,
          formType: 'radio',
          options: 'store/packageStatus',
          attrs: {
            isButton: true
          }
        },
        package_no: {
          label: '包裹号',
          value: row.package_no,
          attrs: {
            disabled: true
          }
        },
        size_type: {
          value: row.size_type,
          label: '包裹大小',
          formType: 'radio',
          options: '/orderPackage/sizeType',
          required: false,
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: row.weight,
          required: false,
          formType: 'number',
          attrs: {
            append: 'kg',
            clearable: true
          }
        }
      };
      this.formAction = 'orderPackage/edit';
      this.$refs.wDialogForm.visible = true;
    },
    async printPackage() {
      if (!this.getForm().model.weight || !this.getForm().model.package_no) {
        this.$message.error('无效的包裹');
        return;
      }
      this.$refs.printRecipt.printReceipt();
    },
    getForm() {
      return this.$refs.wForm;
    },
    fillZero(v, len) {
      return '0'.repeat(len - v.length) + v;
    }
  }
};