export default {
  name: 'UserCouponDialog',
  data() {
    return {
      module: 'user',
      formAction: '/user/coupon',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      nickname,
      coupon_id
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        nickname: {
          label: '昵称',
          value: nickname,
          readonly: true
        },
        coupon_id: {
          label: '优惠券',
          value: coupon_id,
          formType: 'select',
          options: '/coupon/admin'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};