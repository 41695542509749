var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      module: _vm.module,
      operates: _vm.operates
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    }
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "角色",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%"
    },
    on: {
      done: _vm.getList
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogAdminVisible,
      title: _vm.dialogTitle,
      center: "",
      width: "640px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogAdminVisible = $event;
      }
    }
  }, [_c("el-transfer", {
    attrs: {
      "filter-method": _vm.filterAdmins,
      titles: ["全部", "已选择"],
      data: _vm.admins,
      "render-content": _vm.renderFunc,
      "filter-placeholder": "请输入ID或名字",
      filterable: ""
    },
    model: {
      value: _vm.roleAdmins.admin_id,
      callback: function ($$v) {
        _vm.$set(_vm.roleAdmins, "admin_id", $$v);
      },
      expression: "roleAdmins.admin_id"
    }
  }), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogAdminVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateAdmin
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("permission", {
    ref: "permissionDialog"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };