var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "80%",
      itemWidth: "33%"
    },
    on: {
      done: _vm.done
    },
    scopedSlots: _vm._u([{
      key: "orderGoods",
      fn: function ({
        model
      }) {
        return _vm._l(model.orderGoods, function (item) {
          return _c("div", {
            staticClass: "w100 flex"
          }, [_c("span", {
            directives: [{
              name: "copy",
              rawName: "v-copy",
              value: item.third_goods_id,
              expression: "item.third_goods_id"
            }],
            staticClass: "cursor"
          }, [_vm._v(_vm._s(item.third_goods_id))]), _c("el-image", {
            staticClass: "w-image-40 ml20",
            attrs: {
              src: item.thumb,
              "preview-src-list": model.orderGoods.map(i => i.thumb)
            }
          })], 1);
        });
      }
    }, {
      key: "orderNo",
      fn: function ({
        model
      }) {
        return [_c("el-link", {
          attrs: {
            href: "/#/order/order/get?id=" + model.order_id,
            target: "_blank"
          }
        }, [_vm._v(_vm._s(model.order_no))])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };