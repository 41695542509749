export default {
  name: 'SiteFeeAddDialog',
  data() {
    return {
      module: 'siteFee',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      site_id,
      status,
      amount,
      fee,
      currency
    }) {
      this.form = {
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        amount: {
          label: '订单金额',
          value: amount,
          formType: 'number',
          tooltip: '订单金额不低于该值'
        },
        fee: {
          label: '手续费',
          value: fee,
          formType: 'number',
          attrs: {
            step: 0.01
          }
        },
        currency: {
          label: '货币类型',
          value: currency,
          formType: 'radio',
          options: '/siteConfig/currency'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};