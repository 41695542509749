import "core-js/modules/es.array.push.js";
import OrderCombineAddDialog from './dialog/orderCombineAddDialog';
import OrderCombineImportDialog from './dialog/orderCombineImportDialog';
import WorkAddDialog from '../work/dialog/workAddDialog';
import Print from '@/components/Print/index';
import { download } from '@/utils/download';
export default {
  name: 'OrderCombineIndex',
  components: {
    OrderCombineAddDialog,
    WorkAddDialog,
    OrderCombineImportDialog,
    Print
  },
  data() {
    return {
      module: 'orderCombine',
      currentTab: '',
      operates: {
        del: false,
        look: false,
        add: true,
        edit: true,
        multiDel: false,
        other: [{
          title: '工单',
          type: 'warning',
          show: row => !row.works || row.works.length === 0,
          click: row => this.addWork(row)
        }]
      },
      form: {},
      printItem: {},
      printTableList: []
    };
  },
  methods: {
    changeTab(v) {
      this.currentTab = v;
    },
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$router.push({
        name: 'OrderCombineGet',
        query: {
          id: row.id
        }
      });
    },
    onAdd() {
      this.$refs.orderCombineAddDialog.open({});
    },
    toHandle() {
      const list = this.$refs.wTable.$refs.table.selection.filter(i => i.status === 0).map(i => i.id);
      if (list.length > 0) {
        this.$confirm('确认受理吗？', '受理提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$w_fun.post(`${this.module}/handle`, {
            id: list
          }).then(res => {
            this.getList();
          });
        }).catch(() => {});
      }
    },
    addWork(row) {
      this.$refs.workAddDialog.open({
        obj_id: row.id,
        obj_title: '国际发货：' + row.order_no,
        work_type_id: 1,
        desc: '国际发货'
      });
    },
    exportDetail() {
      download('export/run', {
        order_combine_id_match: this.$refs.wTable.$refs.table.selection.map(i => i.id),
        _api: 'orderCombineExport/index',
        _export: 1
      });
    },
    async printList() {
      this.printTableList = this.$refs.wTable.$refs.table.selection;
      for (const item of this.$refs.wTable.$refs.table.selection) {
        this.printItem = item;
        await this.$nextTick();
        await this.$nextTick();
        this.$refs.print.handlePrint();
      }
      this.printItem = {};
    }
  }
};