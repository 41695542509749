var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    ref: "printcontent",
    staticClass: "printcontents",
    attrs: {
      id: "printcontents"
    }
  }, [_vm._t("print")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };