import AdminSettingAddDialog from "@/views/adminSetting/dialog/adminSettingAddDialog.vue";
export default {
  name: 'OrderRefundAddDialog',
  components: {
    AdminSettingAddDialog
  },
  data() {
    return {
      module: 'orderRefund',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      order_id,
      order_combine_id,
      exchange_rate,
      amount,
      refund_amount
    }) {
      this.form = {
        order_pay_id: {
          show: false,
          value: id
        },
        order_id: {
          show: false,
          value: order_id
        },
        order_combine_id: {
          show: false,
          value: order_combine_id
        },
        amount: {
          label: '退款金额',
          value: 0,
          required: true,
          formType: 'amount',
          attrs: {
            exchange_rate: exchange_rate
          },
          max: parseFloat((amount - refund_amount).toFixed(2))
        },
        refund_aim_type: {
          value: undefined,
          label: '退款类型',
          formType: 'select',
          options: '/orderRefund/refundAimType'
        },
        reason: {
          label: '原因',
          value: undefined,
          formType: 'select',
          options: 'adminSetting/valueSelect?module_match=orderRefundReason',
          attrs: {
            quickAdd: () => {
              this.$refs.adminSettingAddDialog.open({
                module: 'orderRefundReason'
              });
            }
          }
        },
        remark: {
          label: '备注',
          value: '',
          required: false
        }
      };
      this.formAction = this.module + '/add';
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};