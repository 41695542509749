import BannerAddDialog from './dialog/bannerAddDialog.vue';
export default {
  title: 'BannerIndex',
  components: {
    BannerAddDialog
  },
  data() {
    return {
      module: 'banner',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      },

      currentTab: ''
    };
  },
  methods: {
    changeTab(v) {
      this.currentTab = v;
    },
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.bannerAddDialog.open(row);
    },
    onAdd() {
      this.$refs.bannerAddDialog.open({
        site_id: parseInt(this.currentTab) || 0
      });
    }
  }
};