var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog", {
    ref: "wDialogForm",
    attrs: {
      title: "绑定用户微信",
      width: _vm.$w_fun.isMobile() ? "100%" : "60%"
    },
    on: {
      done: v => _vm.$emit("done", v)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            size: "mini"
          },
          on: {
            click: function ($event) {
              _vm.visible = false;
            }
          }
        }, [_vm._v("取消")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("p", [_vm._v("请需要绑定的用户使用微信扫描下方二维码(注：需先关注微信公众号)：")]), _c("div", {
    staticClass: "fresh"
  }, [_c("div", {
    staticClass: "code"
  }, [_c("img", {
    attrs: {
      src: _vm.bind_url
    }
  }), _c("div", {
    staticClass: "mask",
    on: {
      click: _vm.getCode
    }
  }, [_vm._v("刷新二维码")])]), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.getList
    }
  }, [_vm._v("刷新列表")])], 1), _c("w-table", {
    ref: "table",
    attrs: {
      actions: _vm.actions,
      operates: _vm.operates,
      pagination: false,
      maxHeight: "400px"
    },
    on: {
      getList: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };