export default {
  name: 'TransFeeItemAddDialog',
  data() {
    return {
      module: 'transFeeItem',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      trans_category_id,
      trans_fee_id,
      weight,
      fee
    }) {
      this.form = {
        trans_category_id: {
          label: '一级渠道',
          value: trans_category_id,
          formType: 'select',
          options: '/transCategory/select'
        },
        trans_fee_id: {
          label: '二级渠道',
          value: trans_fee_id,
          formType: 'select',
          options: '/transFee/select'
        },
        weight: {
          label: '重量',
          value: weight,
          formType: 'number',
          attrs: {
            step: 0.01
          }
        },
        fee: {
          label: '费用',
          value: fee,
          formType: 'number',
          attrs: {
            step: 0.01
          }
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};