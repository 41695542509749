export default {
  name: 'OrderPayAddDialog',
  data() {
    return {
      module: 'orderPay',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      trade_info_id,
      pay_no,
      status,
      amount,
      paid_at,
      payment_type,
      user_id,
      subject,
      trade_no,
      pay_count,
      pay_aim_type,
      exchange_rate,
      refund_amount,
      is_multiple
    }) {
      this.form = {
        trade_info_id: {
          label: '',
          value: trade_info_id,
          formType: 'select',
          options: '/tradeInfo/select'
        },
        pay_no: {
          label: '支付单编号',
          value: pay_no
        },
        status: {
          label: '支付状态',
          value: status,
          formType: 'status'
        },
        amount: {
          label: '金额',
          value: amount,
          formType: 'number'
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        payment_type: {
          label: '支付方式',
          value: payment_type,
          formType: 'select',
          options: {
            url: '/orderPay/create',
            key: 'paymentType'
          }
        },
        user_id: {
          label: '',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        subject: {
          label: '支付标题',
          value: subject
        },
        trade_no: {
          label: '流水号',
          value: trade_no
        },
        pay_count: {
          label: '',
          value: pay_count,
          formType: 'number'
        },
        pay_aim_type: {
          label: '支付类型',
          value: pay_aim_type,
          formType: 'select',
          options: {
            url: '/orderPay/create',
            key: 'payAimType'
          }
        },
        exchange_rate: {
          label: '汇率',
          value: exchange_rate,
          formType: 'number'
        },
        refund_amount: {
          label: '退款金额',
          value: refund_amount,
          formType: 'number'
        },
        is_multiple: {
          label: '是否是批量支付',
          value: is_multiple,
          formType: 'boolean'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};