import { post } from '@/libs/request';
import WTable from '@/components/w/components/w-table/index.vue';
export default {
  name: 'BindDialog',
  components: {
    WTable
  },
  data() {
    return {
      module: 'siteAccountUser',
      actions: {
        index: [{
          nickname: '111',
          avatar: '222',
          created_at: '333'
        }],
        columns: [{
          v: 'nickname',
          label: '微信名'
        }, {
          v: 'avatar',
          label: '头像',
          render: 'image'
        }, {
          v: 'created_at',
          label: '绑定时间'
        }]
      },
      operates: {
        add: false,
        edit: false,
        look: false,
        del: {
          url: '/siteAccountUser/delete'
        }
      },
      visible: false,
      bind_url: '',
      data: {}
    };
  },
  methods: {
    open(data) {
      this.data = data;
      this.getCode();
      this.getList();
      this.visible = true;
    },
    getCode() {
      post('/siteAccountUser/binding_code', {
        id: this.data.id
      }).then(res => {
        this.bind_url = res.data.qr_code;
      });
    },
    getList(data) {
      post('/siteAccountUser/index', {
        id: this.data.id
      }).then(res => {
        if (res.data) {
          this.actions.index = res.data.list.data;
        }
        this.refresh();
      });
    },
    refresh() {
      this.$refs.table && this.$refs.table.onRefresh();
    }
  }
};