import "core-js/modules/es.array.push.js";
export default {
  title: 'OrderPackageGet',
  data() {
    return {};
  },
  created() {
    this.$w_fun.post('orderPackage/get', {
      id: this.$route.query.id
    }).then(res => {
      this.$router.push({
        name: 'OrderGet',
        query: {
          id: row.order_id
        }
      });
    });
  }
};