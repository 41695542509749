export default {
  name: 'OrderAddDialog',
  data() {
    return {
      module: 'order',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      site_id,
      user_id,
      paid_at,
      parent_id,
      amount,
      trans_amount,
      refund,
      discount,
      origin_amount,
      goods_amount,
      fee,
      cost,
      status,
      user_status,
      pay_status,
      order_no,
      address_code,
      third_order_no,
      comment,
      remark
    }) {
      this.form = {
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select'
        },
        user_id: {
          label: '会员',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        parent_id: {
          label: '父订单',
          value: parent_id,
          formType: 'select',
          options: '/parent/select',
          required: false
        },
        amount: {
          label: '金额',
          value: amount,
          formType: 'number'
        },
        trans_amount: {
          label: '运费',
          value: trans_amount,
          formType: 'number'
        },
        refund: {
          label: '退款金额',
          value: refund,
          formType: 'number'
        },
        discount: {
          label: '折扣金额',
          value: discount,
          formType: 'number'
        },
        origin_amount: {
          label: '原始金额',
          value: origin_amount,
          formType: 'number'
        },
        goods_amount: {
          label: '商品金额',
          value: goods_amount,
          formType: 'number'
        },
        fee: {
          label: '手续费',
          value: fee,
          formType: 'number'
        },
        cost: {
          label: '成本',
          value: cost,
          formType: 'number'
        },
        status: {
          label: '订单状态',
          value: status,
          formType: 'status'
        },
        user_status: {
          label: '用户端状态',
          value: user_status,
          formType: 'number'
        },
        pay_status: {
          label: '支付状态',
          value: pay_status,
          formType: 'number'
        },
        order_no: {
          label: '订单号',
          value: order_no
        },
        address_code: {
          label: '包裹码',
          value: address_code
        },
        third_order_no: {
          label: '三方平台订单号',
          value: third_order_no
        },
        comment: {
          label: '用户留言',
          value: comment
        },
        remark: {
          label: '备注',
          value: remark
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};