var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogForm",
    attrs: {
      title: "指派",
      form: _vm.form,
      action: "work/assign",
      "label-width": "100px"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };