export default {
  name: 'OrderCombineAddDialog',
  data() {
    return {
      module: 'orderCombine',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      paid_at,
      delivery_at,
      amount,
      discount,
      origin_amount,
      cost,
      status,
      user_status,
      pay_status,
      order_no,
      comment,
      remark,
      trans_fee_id
    }) {
      this.form = {
        user_id: {
          label: '会员',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        delivery_at: {
          label: '发货时间',
          value: delivery_at
        },
        amount: {
          label: '金额',
          value: amount,
          formType: 'number'
        },
        discount: {
          label: '折扣金额',
          value: discount,
          formType: 'number'
        },
        origin_amount: {
          label: '原始金额',
          value: origin_amount,
          formType: 'number'
        },
        cost: {
          label: '成本',
          value: cost,
          formType: 'number'
        },
        status: {
          label: '国际发货状态',
          value: status,
          formType: 'status'
        },
        user_status: {
          label: '用户端状态',
          value: user_status,
          formType: 'number'
        },
        pay_status: {
          label: '支付状态',
          value: pay_status,
          formType: 'number'
        },
        order_no: {
          label: '订单号',
          value: order_no
        },
        comment: {
          label: '用户留言',
          value: comment
        },
        remark: {
          label: '备注',
          value: remark
        },
        trans_fee_id: {
          label: '运费类型',
          value: trans_fee_id,
          formType: 'select',
          options: '/transFee/select'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};