var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      labelWidth: "180px",
      itemWidth: "45%"
    },
    on: {
      done: v => _vm.$emit("done", v)
    }
  }), _c("AdditionCategoryAddDialog", {
    ref: "AdditionCategoryAddDialog",
    on: {
      done: function ($event) {
        _vm.getForm().refreshOptions("addition_category_id");
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };