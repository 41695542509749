export default {
  name: 'UserPointChangeAddDialog',
  data() {
    return {
      module: 'userPointChange',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      point,
      after_change,
      change_type,
      obj_id,
      title
    }) {
      this.form = {
        user_id: {
          label: '',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        point: {
          label: '变动积分',
          value: point,
          formType: 'number'
        },
        after_change: {
          label: '变动侯余额',
          value: after_change,
          formType: 'number'
        },
        change_type: {
          label: '变动类型',
          value: change_type,
          formType: 'number'
        },
        obj_id: {
          label: '变动关联数据',
          value: obj_id,
          formType: 'select',
          options: '/obj/select'
        },
        title: {
          label: '变动说明',
          value: title
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};