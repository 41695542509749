var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100"
  }, [_c("w-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      actions: _vm.actions,
      query: _vm.query,
      pagination: false,
      asyncSummary: false,
      "max-height": "100%",
      getList: v => _vm.$emit("getList", v)
    }
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "新增",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      labelWidth: "140px"
    },
    on: {
      done: _vm.formDone
    }
  }), _c("OrderRefundAddDialog", {
    ref: "orderRefundAddDialog",
    on: {
      done: function ($event) {
        return _vm.$emit("done");
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };