import { render, staticRenderFns } from "./userBargainAddDialog.vue?vue&type=template&id=402c5fb7&"
import script from "./userBargainAddDialog.vue?vue&type=script&lang=js&"
export * from "./userBargainAddDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/miles_4fMM/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('402c5fb7')) {
      api.createRecord('402c5fb7', component.options)
    } else {
      api.reload('402c5fb7', component.options)
    }
    module.hot.accept("./userBargainAddDialog.vue?vue&type=template&id=402c5fb7&", function () {
      api.rerender('402c5fb7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/userBargain/dialog/userBargainAddDialog.vue"
export default component.exports