export default {
  name: 'UserBillAddDialog',
  data() {
    return {
      module: 'userBill',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      amount,
      after_change,
      change_type,
      obj_id,
      title,
      frozen_type,
      admin_id,
      payment_type
    }) {
      this.form = {
        user_id: {
          label: '',
          value: user_id,
          formType: 'select',
          options: '/user/select'
        },
        amount: {
          label: '变动金额',
          value: amount,
          formType: 'number'
        },
        after_change: {
          label: '变动后余额',
          value: after_change,
          formType: 'number'
        },
        change_type: {
          label: '变动类型',
          value: change_type,
          formType: 'select',
          options: {
            url: '/userBill/create',
            key: 'changeType'
          }
        },
        obj_id: {
          label: '变动关联数据',
          value: obj_id,
          formType: 'select',
          options: '/obj/select'
        },
        title: {
          label: '变动说明',
          value: title
        },
        frozen_type: {
          label: '冻结类型',
          value: frozen_type,
          formType: 'select',
          options: {
            url: '/userBill/create',
            key: 'frozenType'
          }
        },
        admin_id: {
          label: '操作人',
          value: admin_id,
          formType: 'select',
          options: '/admin/select'
        },
        payment_type: {
          label: '支付方式',
          value: payment_type,
          formType: 'select',
          options: {
            url: '/userBill/create',
            key: 'paymentType'
          }
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};