export default {
  name: 'CouponAddDialog',
  data() {
    return {
      module: 'coupon',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      title,
      value,
      min_order_amount,
      coupon_type,
      use_type,
      get_type,
      status,
      get_start,
      get_end,
      use_start,
      use_end,
      period,
      currency,
      site_id,
      total_count,
      each_month
    }) {
      this.form = {
        title: {
          label: '优惠券名称',
          value: title,
          width: '100%'
        },
        coupon_type: {
          label: '优惠券类型',
          value: coupon_type,
          formType: 'select',
          options: {
            url: '/coupon/create',
            key: 'couponType'
          },
          methods: {
            change: v => {
              const index = this.getForm().formItems.findIndex(i => i.prop === 'value');
              if (v === 0) {
                this.getForm().formItems[index].attrs.append = '';
                this.getForm().formItems[index].max = undefined;
              } else {
                this.getForm().formItems[index].attrs.append = '%';
                this.getForm().formItems[index].max = 100;
                this.getForm().formItems[index].min = 1;
              }
            }
          }
        },
        total_count: {
          label: '发放张数',
          value: total_count,
          formType: 'number',
          min: 0,
          tooltip: '0表示不限制'
        },
        each_month: {
          label: '每人每月最多使用次数',
          value: each_month,
          formType: 'number',
          min: 0,
          tooltip: '0表示不限制'
        },
        value: {
          label: '优惠券金额',
          value: value,
          formType: 'number',
          tooltip: '打折时，该百分比为收入百分比，例如打7折就填70'
        },
        min_order_amount: {
          label: '订单满多少可以使用',
          value: min_order_amount,
          formType: 'number'
        },
        use_type: {
          label: '使用范围',
          value: use_type,
          formType: 'select',
          options: {
            url: '/coupon/create',
            key: 'useType'
          }
        },
        get_type: {
          label: '获取类型',
          value: get_type,
          formType: 'select',
          options: {
            url: '/coupon/create',
            key: 'getType'
          }
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        get_start: {
          label: '领取开始时间',
          value: get_start || '',
          formType: 'datePicker',
          required: false
        },
        get_end: {
          label: '领取结束时间',
          value: get_end || '',
          formType: 'datePicker',
          required: false
        },
        use_start: {
          label: '使用开始时间',
          value: use_start || '',
          formType: 'datePicker',
          required: false
        },
        use_end: {
          label: '使用结束时间',
          value: use_end || '',
          formType: 'datePicker',
          required: false
        },
        period: {
          label: '有效期',
          value: period,
          formType: 'number',
          attrs: {
            append: '天'
          }
        },
        currency: {
          show: m => m.coupon_type === 0,
          label: '货币类型',
          value: currency,
          formType: 'radio',
          options: '/siteConfig/currency',
          attrs: {
            isButton: true
          }
        },
        site_id: {
          label: '适用站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select',
          required: false,
          tooltip: '不设置站点表示全站通用'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};