import AdditionCategoryAddDialog from '@/views/additionCategory/dialog/additionCategoryAddDialog.vue';
export default {
  name: 'AdditionAddDialog',
  components: {
    AdditionCategoryAddDialog
  },
  data() {
    return {
      module: 'addition',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      addition_category_id,
      site_id,
      title,
      tips,
      status,
      currency,
      fee,
      added_fee,
      added_count,
      is_percent,
      is_comment,
      article_id,
      position_type = []
    }) {
      this.form = {
        title: {
          label: '名称',
          value: title,
          width: '100%'
        },
        addition_category_id: {
          label: '增值服务分类',
          value: addition_category_id,
          formType: 'select',
          options: '/additionCategory/select',
          attrs: {
            quickAdd: () => {
              this.$refs.AdditionCategoryAddDialog.open({});
            }
          }
        },
        // site_id: {
        //   label: '站点',
        //   value: site_id,
        //   formType: 'select',
        //   options: '/siteConfig/select'
        // },
        tips: {
          label: '简介',
          value: tips,
          required: false
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        currency: {
          label: '货币类型',
          value: currency,
          formType: 'radio',
          options: '/siteConfig/currency'
        },
        fee: {
          label: '费用',
          value: fee,
          formType: 'number',
          attrs: {
            step: 0.01
          }
        },
        added_fee: {
          label: '加收费用',
          value: added_fee,
          formType: 'number',
          required: false,
          attrs: {
            step: 0.01
          }
        },
        added_count: {
          label: '加收数量',
          value: added_count,
          formType: 'number',
          tooltip: '每多少个商品加收一次费用',
          min: 1,
          show: m => m.added_fee > 0
        },
        position_type: {
          label: '显示位置',
          value: position_type,
          formType: 'checkbox',
          width: '100%',
          options: {
            url: `/${this.module}/create`,
            key: 'positionType'
          }
        },
        // is_percent: { label: '是否为百分比收钱', value: is_percent, formType: 'boolean' },
        is_comment: {
          label: '是否填写备注',
          value: is_comment,
          formType: 'boolean'
        },
        article_id: {
          label: '详细文章说明',
          value: article_id,
          formType: 'select',
          options: '/article/select',
          required: false
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};