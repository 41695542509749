import UserAddressAddDialog from './dialog/userAddressAddDialog';
export default {
  name: 'UserAddressIndex',
  components: {
    UserAddressAddDialog
  },
  data() {
    return {
      module: 'userAddress',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.userAddressAddDialog.open(row);
    },
    onAdd() {
      this.$refs.userAddressAddDialog.open({});
    }
  }
};