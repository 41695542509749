export default {
  name: 'SiteAccountAddDialog',
  data() {
    return {
      module: 'siteAccount',
      formAction: '',
      form: {},
      readonly: false,
      title: ''
    };
  },
  methods: {
    setForm({
      id,
      site_id,
      // 3 煤炉    4 日拍
      vibe,
      account,
      account_name,
      password,
      email,
      phone,
      status,
      remark
    }) {
      this.form = {
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select',
          methods: {
            change: v => {
              this.getForm().formItems.map((item, i) => {
                if (item.prop === 'account') {
                  item.label = v === 3 ? 'client_id' : '账号';
                }
                if (item.prop === 'password') {
                  item.label = v === 3 ? 'secret' : '密码';
                }
              });
            }
          }
        },
        account: {
          show: v => v.site_id !== 4,
          label: site_id === 3 ? 'client_id' : '账号',
          value: account
        },
        email: {
          show: v => v.site_id === 4,
          label: '账号',
          value: email,
          pattern: 'email'
        },
        password: {
          // show: v=> v.site_id !== 4,
          label: site_id === 3 ? 'secret' : '应用专用密码',
          value: password
        },
        vibe: {
          show: v => v.site_id === 3,
          label: '正式环境',
          value: vibe,
          formType: 'switch',
          options: [{
            label: '否',
            value: 0
          }, {
            label: '是',
            value: 1
          }]
        },
        account_name: {
          // show: v=> v.site_id === 4,
          label: '账号昵称',
          value: account_name
        },
        phone: {
          show: v => v.site_id === 4,
          label: '账号关联电话',
          value: phone
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        remark: {
          label: '备注',
          value: remark,
          required: false
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
        this.title = '编辑';
      } else {
        this.formAction = `${this.module}/add`;
        this.title = '新增';
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    beforeSubmit(m) {
      // 日拍
      if (m.site_id === 4) {
        m.account = m.email;
      }
      m.remark = m.remark || '-';
      return m;
    }
  }
};