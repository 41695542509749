export default {
  name: 'Permission',
  data() {
    return {
      dialogVisible: false,
      allPermission: [],
      permission: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      roleId: undefined
    };
  },
  methods: {
    getRoleAdminList(roleId) {
      this.dialogVisible = true;
      this.roleId = roleId;
      this.$w_fun.post('role/permission', {
        role_id: roleId
      }).then(response => {
        this.allPermission = response.data.permissions;
        this.permission = response.data.role.permissions;
      });
    },
    updatepermission() {
      this.permission = this.$refs.tree.getCheckedKeys();
      this.$w_fun.post('role/updatePermission', {
        role_id: this.roleId,
        admin_permission_id: this.permission
      }).then(response => {
        this.dialogVisible = false;
        this.$message({
          type: 'success',
          message: '更新成功!'
        });
      });
    }
  }
};