var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-collapse", {
    attrs: {
      size: _vm.size_
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "基础信息",
      name: "order"
    }
  }, [_c("w-form", {
    ref: "wForm",
    attrs: {
      title: "订单数据",
      form: _vm.formOrder,
      readonly: true,
      itemWidth: "32%",
      labelWidth: _vm.size_ === "mini" ? "110px" : "130px"
    },
    scopedSlots: _vm._u([{
      key: "orderImages",
      fn: function ({
        model
      }) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: _vm.uploadOrderImage
          }
        }, [_vm._v("上传")]), _c("div", {
          staticClass: "w100 mt10"
        }, _vm._l(_vm.orderImages, function (image, index) {
          return _c("div", {
            key: index,
            staticClass: "w-image-70 border-f1f1f1 m3 p3 dp-i-b relative o-h image-list radius5"
          }, [_c("el-image", {
            staticStyle: {
              width: "64px",
              height: "64px"
            },
            attrs: {
              src: image.src,
              "preview-src-list": _vm.orderImages.map(i => i.src),
              fit: "contain"
            }
          }), _c("div", {
            staticClass: "close-box cursor",
            on: {
              click: function ($event) {
                return _vm.deleteOrderImage(image);
              }
            }
          }, [_c("i", {
            staticClass: "el-icon-close"
          })])], 1);
        }), 0)];
      }
    }])
  }), _vm.order.orderChanges && _vm.order.orderChanges.length > 0 && _vm.order.orderChanges[0].status === 4 ? _c("p", {
    staticClass: "p5 f16 b red ml20"
  }, [_vm._v("用户已拒绝订单变更，请及时处理")]) : _vm._e(), _vm.canEdit ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.editOrder
    }
  }, [_vm._v("修改")]) : _vm._e(), _vm.order.freeze_amount > 0 && _vm.canEdit ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "warning",
      size: _vm.size_
    },
    on: {
      click: _vm.auctionsPay
    }
  }, [_vm._v("核算扣款")]) : _vm._e(), _vm.order.status === 1 && _vm.canEdit && _vm.order.site_id !== 4 ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "danger",
      size: _vm.size_
    },
    on: {
      click: _vm.toCancel
    }
  }, [_vm._v("取消订单")]) : _vm._e()], 1), _c("el-collapse-item", {
    attrs: {
      title: "商品",
      name: "orderGoods"
    }
  }, [_vm.order.site_id === 2 && _vm.canEdit ? _c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.editGoods({
          order_id: _vm.order.id
        });
      }
    }
  }, [_vm._v("新增")]) : _vm._e(), _vm.order.status === 1 && _vm.canEdit ? _c("el-button", {
    attrs: {
      type: "warning",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.toPurchase({
          order_id: _vm.order.id
        });
      }
    }
  }, [_vm._v("采购")]) : _vm._e(), _vm.order.site_id === 2 && _vm.canEdit ? _c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.editTransAmount
    }
  }, [_vm._v("修改运费")]) : _vm._e(), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderGoods,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "名称"
    }
  }), _vm.order.site_id > 2 ? _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "ID",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.third_goods_id,
            expression: "scope.row.third_goods_id"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.third_goods_id))])];
      }
    }], null, false, 2968261678)
  }) : _vm._e(), _vm.order.site_id > 2 ? _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "卖家",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.third_seller_id,
            expression: "scope.row.third_seller_id"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.third_seller_id))])];
      }
    }], null, false, 1263203135)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      prop: "variant_title",
      label: "规格",
      "show-overflow-tooltip": "",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "link",
      label: "链接"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.link,
            expression: "scope.row.link"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.link))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "图片",
      width: "65"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.thumb ? _c("el-image", {
          staticClass: "w-image-40",
          attrs: {
            src: scope.row.thumb,
            fit: "contain",
            "preview-src-list": _vm.orderGoods.map(i => i.thumb)
          }
        }) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "price",
      label: "价格(円)",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "quantity",
      label: "数量",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "amount",
      label: "金额(円)",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "discount",
      label: "折扣",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "addition_id",
      label: "验货拍照",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.addition_id ? _c("p", {
          attrs: {
            title: scope.row.addition_comment
          }
        }, [_vm._v(_vm._s(scope.row.addition && scope.row.addition.title))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 100 * _vm.$store.getters["sizeRate"],
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [scope.row.status < 4 && _vm.canEdit ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.editGoods(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), scope.row.status === 1 && _vm.orderGoods.length > 1 && _vm.order.site_id === 2 && _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.toDelGoods(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _vm.order.site_id === 1 ? [_c("p", {
    staticClass: "w100 p10"
  }, [_vm._v("支付方式：" + _vm._s(_vm.order.orderInsteadInfo && _vm.order.orderInsteadInfo.insteadPayType && _vm.order.orderInsteadInfo.insteadPayType.title))]), _c("div", {
    staticClass: "w100"
  }, _vm._l(_vm.goodsImages, function (goodsImage, index) {
    return _c("el-image", {
      key: index,
      staticClass: "w-image-70",
      attrs: {
        src: goodsImage,
        "preview-src-list": _vm.goodsImages.concat(_vm.payImages),
        fit: "contain"
      }
    });
  }), 1), _c("div", {
    staticClass: "w100"
  }, _vm._l(_vm.payImages, function (goodsImage, index) {
    return _c("el-image", {
      key: index,
      staticClass: "w-image-70",
      attrs: {
        src: goodsImage,
        "preview-src-list": _vm.payImages.concat(_vm.goodsImages),
        fit: "contain"
      }
    });
  }), 1)] : _vm._e()], 2), _c("el-collapse-item", {
    attrs: {
      title: "包裹",
      name: "orderPackages"
    }
  }, [_vm.canEdit ? _c("el-button", {
    attrs: {
      size: _vm.size_,
      type: "primary"
    },
    on: {
      click: _vm.addPackage
    }
  }, [_vm._v("新增")]) : _vm._e(), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderPackages,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "商品",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.orderGoods, function (goods) {
          return _c("el-image", {
            key: goods.id,
            staticClass: "w-image-40",
            attrs: {
              src: goods.thumb,
              "preview-src-list": scope.row.orderGoods.map(i => i.thumb),
              fit: "contain"
            }
          });
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "采购信息",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.third_order_no,
            expression: "scope.row.third_order_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v("平台订单：" + _vm._s(scope.row.third_order_no))]), _c("p", {
          staticClass: "omit",
          attrs: {
            title: scope.row.purchase_at
          }
        }, [_vm._v("采购时间：" + _vm._s(scope.row.purchase_at))]), _c("p", {
          staticClass: "omit",
          attrs: {
            title: scope.row.remark
          }
        }, [_vm._v("备注：" + _vm._s(scope.row.remark))]), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editPurchase(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "status_text",
      label: "状态",
      width: "70"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "验货拍照",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.addition_id ? _c("p", [_vm._v("类型：" + _vm._s(scope.row.addition && scope.row.addition.title))]) : _vm._e(), scope.row.addition_id ? _c("p", {
          staticClass: "omit",
          attrs: {
            title: scope.row.addition_comment
          }
        }, [_vm._v("备注：" + _vm._s(scope.row.addition_comment))]) : _vm._e(), scope.row.addition_id ? _c("p", [_vm._v("费用：" + _vm._s(scope.row.addition_amount) + "円")]) : _vm._e(), scope.row.addition_id ? _c("p", [_vm._v("数量：" + _vm._s(scope.row.goods_quantity))]) : _vm._e(), scope.row.addition_id && scope.row.addition_amount > 0 ? _c("p", [_vm._v("支付：" + _vm._s(scope.row.addition_pay_status_text))]) : _vm._e(), scope.row.works && scope.row.works.filter(i => i.work_type_id === 2).length > 0 ? _c("p", [_vm._v("工单：" + _vm._s(scope.row.works.filter(i => i.work_type_id === 2)[0].status_text) + '">')]) : _vm._e(), _c("el-button-group", [_vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editAddition(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), scope.row.addition_pay_status === 0 && scope.row.addition_amount > 0 && _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.toPay(scope.row, "addition_amount");
            }
          }
        }, [_vm._v("发送给用户支付")]) : _vm._e(), scope.row.addition_pay_status === 1 && (!scope.row.works || scope.row.works.length === 0) && _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.addWork(scope.row);
            }
          }
        }, [_vm._v("工单")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "照片"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._l(scope.row.orderPackagePhotos.slice(0, 3), function (photo, index) {
          return _c("el-image", {
            key: index,
            staticClass: "w-image-40 ml5",
            attrs: {
              src: photo.thumb,
              "preview-src-list": scope.row.orderPackagePhotos.map(i => i.thumb),
              fit: "contain"
            }
          });
        }), _vm._v(" " + _vm._s(scope.row.orderPackagePhotos.length > 3 ? "..." : "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "快递信息",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", [_vm._v("快递公司：" + _vm._s(scope.row.tracking_company))]), _c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.tracking_no,
            expression: "scope.row.tracking_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v("快递单号:" + _vm._s(scope.row.tracking_no))]), _c("p", [_vm._v("发货地址：" + _vm._s(scope.row.from_address))]), _c("p", [_vm._v("　　运费：" + _vm._s(scope.row.trans_amount) + "円")]), scope.row.trans_amount > 0 ? _c("p", [_vm._v("　　支付：" + _vm._s(scope.row.trans_pay_status_text))]) : _vm._e(), _c("el-button-group", [_vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editTrans(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), scope.row.trans_pay_status === 0 && scope.row.trans_amount > 0 && _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.toPay(scope.row, "trans_amount");
            }
          }
        }, [_vm._v("发送给用户支付")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "仓库",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", [_vm._v("入库时间：" + _vm._s(scope.row.stored_at))]), _c("p", [_vm._v("出库时间：" + _vm._s(scope.row.out_at))]), _c("p", [_vm._v("　货架号：" + _vm._s(scope.row.shelf_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "包裹信息",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.package_no,
            expression: "scope.row.package_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v("　包裹号：" + _vm._s(scope.row.package_no))]), _c("p", [_vm._v("包裹大小：" + _vm._s(scope.row.size_type_text))]), _c("p", [_vm._v("　　重量：" + _vm._s(scope.row.weight ? scope.row.weight + "kg" : ""))]), _vm.canEdit ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editPackage(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "同捆",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.order.site_id > 2 && _vm.canEdit && [1, 2, 3].indexOf(scope.row.status) > -1 ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.addTogether(scope.row);
            }
          }
        }, [_vm._v("新增")]) : _vm._e(), _vm._l(scope.row.togethers, function (together) {
          return _c("div", {
            key: together.id,
            staticClass: "w100 flex border-f1f1f1 p2 mt10"
          }, [_c("div", {
            staticClass: "w80"
          }, [_c("p", [_vm._v("用户：" + _vm._s(together.user.id) + "(" + _vm._s(together.user.phone) + ")")]), _c("p", {
            staticClass: "omit cursor blue",
            attrs: {
              title: together.order_no
            },
            on: {
              click: function ($event) {
                return _vm.toOrder(together.order_id);
              }
            }
          }, [_vm._v("订单号：" + _vm._s(together.order_no))]), _c("p", {
            staticClass: "omit",
            attrs: {
              title: together.orderGoods.map(i => i.title).join("、")
            }
          }, [_vm._v("商品：" + _vm._s(together.orderGoods.map(i => i.title).join("、")))])]), _c("div", {
            staticClass: "w20"
          }, [_vm.order.site_id > 2 && _vm.canEdit && [1, 2, 3].indexOf(scope.row.status) > -1 ? _c("el-button", {
            staticClass: "mt24",
            attrs: {
              size: _vm.size_,
              type: "danger",
              icon: "el-icon-delete"
            },
            on: {
              click: function ($event) {
                return _vm.delTogether(together);
              }
            }
          }) : _vm._e()], 1)]);
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 60 * _vm.$store.getters["sizeRate"],
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [(scope.row.status === 1 || scope.row.status === 2 || scope.row.status === 3) && _vm.canEdit ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.deletePackage(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "订单变更",
      name: "orderChanges"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c("span", [_vm._v("订单变更")]), _vm.order.orderChanges && _vm.order.orderChanges.length > 0 && [0, 2].indexOf(_vm.order.orderChanges[0].status) > -1 ? _c("span", {
          staticClass: "red f14 b ml10"
        }, [_vm._v("有未完成变更")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderChanges,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      label: "ID",
      width: "70"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status_text",
      label: "状态",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "goods_amount",
      label: "商品金额(円)",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.goods_amount !== _vm.order.goods_amount ? _c("p", {
          class: scope.row.goods_amount > _vm.order.goods_amount ? "green" : scope.row.goods_amount < _vm.order.goods_amount ? "red" : ""
        }, [_vm._v(" " + _vm._s(scope.row.goods_amount > _vm.order.goods_amount ? "+" : "") + _vm._s(_vm.bcsub(scope.row.goods_amount, _vm.order.goods_amount)) + " ")]) : _c("p", [_vm._v("无变化")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "trans_amount",
      label: "运费(円)",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.trans_amount !== _vm.order.trans_amount ? _c("p", {
          class: scope.row.trans_amount > _vm.order.trans_amount ? "green" : scope.row.trans_amount < _vm.order.trans_amount ? "red" : ""
        }, [_vm._v(" " + _vm._s(scope.row.trans_amount > _vm.order.trans_amount ? "+" : "") + _vm._s(_vm.bcsub(scope.row.trans_amount, _vm.order.trans_amount)) + " ")]) : _c("p", [_vm._v("无变化")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "amount",
      label: "总金额",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.amount !== 0 ? _c("p", {
          class: scope.row.amount > 0 ? "green" : scope.row.amount < 0 ? "red" : ""
        }, [_vm._v(" " + _vm._s(scope.row.amount > 0 ? "+" : "") + _vm._s(scope.row.amount) + " ")]) : _c("p", [_vm._v("无变化")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderGoodsChanges",
      label: "商品变更明细"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-table", {
          staticClass: "w100",
          attrs: {
            data: scope.row.orderGoodsChanges,
            size: _vm.size_,
            border: "",
            stripe: ""
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "change_type_text",
            label: "类型",
            width: "80"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "title",
            label: "名称"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "link",
            label: "链接"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "price",
            label: "单价(円)",
            width: "80"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "quantity",
            label: "数量",
            width: "80"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "amount",
            label: "商品金额（円）",
            width: "120"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [scope.row.amount !== 0 ? _c("p", {
                class: scope.row.amount > 0 ? "green" : scope.row.amount < 0 ? "red" : ""
              }, [_vm._v(" " + _vm._s(scope.row.amount > 0 ? "+" : "") + _vm._s(scope.row.amount) + " ")]) : _c("p", [_vm._v("无变化")])];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            label: "操作",
            width: 60 * _vm.$store.getters["sizeRate"]
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              row
            }) {
              return [scope.row.status === 0 ? _c("el-button", {
                attrs: {
                  size: _vm.size_,
                  type: "danger"
                },
                on: {
                  click: function ($event) {
                    return _vm.toDelGoodsChange(row);
                  }
                }
              }, [_vm._v("删除")]) : _vm._e()];
            }
          }], null, true)
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: 150 * _vm.$store.getters["sizeRate"],
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [(scope.row.status === 0 || scope.row.status === 2) && _vm.canEdit ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "info"
          },
          on: {
            click: function ($event) {
              return _vm.toCancelChange(scope.row);
            }
          }
        }, [_vm._v("取消")]) : _vm._e(), scope.row.status === 0 && _vm.canEdit ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.toSendChange(scope.row);
            }
          }
        }, [_vm._v("发送给用户确认")]) : _vm._e(), scope.row.status === 2 && _vm.canEdit ? _c("el-button", {
          attrs: {
            size: _vm.size_,
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.toConfirmChange(scope.row);
            }
          }
        }, [_vm._v("帮用户确认")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "支付记录",
      name: "orderPays"
    }
  }, [_vm.canEdit ? _c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.addPay
    }
  }, [_vm._v("新增")]) : _vm._e(), _c("PayTable", {
    ref: "payTable",
    attrs: {
      query: {
        order_id: _vm.order.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderRefunds");
      }
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "退款记录",
      name: "orderRefunds"
    }
  }, [_c("RefundTable", {
    ref: "refundTable",
    attrs: {
      query: {
        order_id: _vm.order.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        _vm.loadDataList("orderPays");
        _vm.loadDataList("orderRefunds");
      }
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "操作历史",
      name: "orderHistories"
    }
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderHistories,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "created_at",
      label: "日期",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "admin_id",
      label: "操作人",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.admin_id === -1 ? "用户" : scope.row.admin_id === 0 ? "系统" : scope.row.admin.nickname) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "comment",
      label: "内容"
    }
  })], 1)], 1)], 1), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%"
    },
    on: {
      done: _vm.formDone
    }
  }), _c("WorkAddDialog", {
    ref: "workAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("OrderRefundAddDialog", {
    ref: "orderRefundAddDialog",
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderRefunds");
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };