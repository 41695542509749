var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-form", {
    ref: "wForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      fullscreen: true,
      itemWidth: "45%",
      labelWidth: "180px"
    },
    on: {
      done: _vm.getList
    }
  }), _c("div", {
    staticClass: "bottom-operation"
  }, [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v("提交")]), _c("el-button", {
    on: {
      click: function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1), _c("ArticleCategoryAddDialog", {
    ref: "articleCategoryAddDialog",
    on: {
      done: function ($event) {
        _vm.getForm().refreshOptions("article_category_id");
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };