import "core-js/modules/es.array.push.js";
import WorkAddDialog from '../work/dialog/workAddDialog';
import OrderRefundAddDialog from '@/views/orderRefund/dialog/orderRefundAddDialog';
import RefundTable from '@/views/orderRefund/refund';
import PayTable from '@/views/orderPay/pay';
import bcmethods from '@/mixins/bcmethods';
export default {
  title: 'OrderGet',
  components: {
    WorkAddDialog,
    OrderRefundAddDialog,
    RefundTable,
    PayTable
  },
  mixins: [bcmethods],
  data() {
    return {
      module: 'order',
      activeNames: ['order', 'orderGoods', 'orderPackages'],
      oldActiveNames: ['order', 'orderGoods', 'orderPackages'],
      order: {
        id: undefined,
        parent_id: 0
      },
      goodsAmount: 0,
      orderGoods: [],
      orderChanges: [],
      orderPackages: [],
      orderHistories: [],
      orderPays: [],
      orderRefunds: [],
      goodsImages: [],
      payImages: [],
      formOrder: {},
      loading: false,
      form: {},
      formAction: '',
      title: '修改'
    };
  },
  computed: {
    size_() {
      return this.$store.getters['size'];
    },
    canEdit() {
      return this.order.parent_id !== 0 && this.order.status !== 99;
    }
  },
  created() {
    this.order.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    getList() {
      this.$w_fun.post(`${this.module}/get`, {
        id: this.order.id
      }).then(res => {
        this.order = res.data.detail;
        this.orderGoods = this.order.orderGoods;
        if (this.order.site_id === 1) {
          this.payImages = this.order.orderInsteadInfo ? this.order.orderInsteadInfo.pay_image || [] : [];
          this.goodsImages = this.order.orderInsteadInfo ? this.order.orderInsteadInfo.goods_image || [] : [];
        }
        this.setOrderForm(this.order);
        this.loadDataList('orderGoods');
        this.loadDataList('orderPackages');
      });
    },
    setOrderForm({
      id,
      site_text,
      user_id,
      paid_at,
      created_at,
      amount,
      paid_amount,
      calc_amount,
      trans_amount,
      store_trans_amount,
      change_amount_range,
      addition_amount,
      userCoupons,
      refund,
      discount,
      origin_amount,
      goods_amount,
      fee,
      exchange_rate,
      cost,
      status,
      status_text,
      user_status,
      pay_status,
      order_no,
      address_code,
      comment,
      remark,
      user = {}
    }) {
      this.formOrder = {
        site_text: {
          label: '站点',
          value: site_text
        },
        order_no: {
          label: '订单号',
          value: order_no,
          copy: true
        },
        status: {
          label: '订单状态',
          value: status_text
        },
        user_id: {
          label: '会员专属码',
          value: user_id
        },
        phone: {
          label: '手机',
          value: user.phone,
          copy: true
        },
        nickname: {
          label: '昵称',
          value: user.nickname
        },
        created_at: {
          label: '下单时间',
          value: created_at
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        amount: {
          label: '金额',
          value: amount,
          tooltip: '下单时支付的金额'
        },
        paid_amount: {
          label: '支付金额',
          value: paid_amount,
          tooltip: '累计支付的金额'
        },
        goods_amount: {
          label: '商品金额',
          value: goods_amount
        },
        trans_amount: {
          label: '运费1',
          value: trans_amount,
          tooltip: '采购时的运费'
        },
        store_trans_amount: {
          label: '运费2',
          value: store_trans_amount,
          tooltip: '货到付款运费'
        },
        fee: {
          label: '手续费',
          value: fee
        },
        discount: {
          label: '折扣金额',
          value: discount,
          tooltip: discount > 0 && userCoupons ? userCoupons.map(i => i.title).join(',') : ''
        },
        refund: {
          label: '退款金额',
          value: refund
        },
        change_amount_range: {
          label: '变更额度',
          value: change_amount_range,
          tooltip: '订单金额变更在多少范围内容可以自动确认'
        },
        exchange_rate: {
          label: '汇率',
          value: exchange_rate
        },
        address_code: {
          label: '包裹码',
          value: address_code
        },
        comment: {
          label: '用户留言',
          value: comment,
          width: '50%'
        },
        remark: {
          label: '备注',
          value: remark,
          width: '50%'
        }
      };
    },
    handleChange(v) {
      if (this.oldActiveNames.length < v.length) {
        const f = v.filter(i => !this.oldActiveNames.includes(i));
        for (const item of f) {
          if (item !== 'order') {
            this.loadDataList(item);
          } else {
            this.getList();
          }
        }
      }
      this.oldActiveNames = v;
    },
    loadDataList(v) {
      if (this.activeNames.indexOf(v) === -1) {
        this.oldActiveNames.push(v);
        this.activeNames.push(v);
      }
      if (v === 'orderRefunds') {
        this.$refs.refundTable.getList();
        return;
      }
      if (v === 'orderPays') {
        this.$refs.payTable.getList();
        return;
      }
      this.$w_fun.post(`${this.module}/${v}`, {
        id: this.order.id
      }).then(res => {
        this[v] = res.data.list;
        if (res.data.goodsAmount) {
          this.goodsAmount = res.data.goodsAmount;
        }
      });
    },
    editOrder() {
      this.form = {
        id: {
          show: false,
          value: this.order.id
        },
        trans_amount: {
          label: '运费',
          value: this.order.trans_amount,
          required: false,
          formType: 'amount',
          show: this.order.status === 1 && (this.order.site_id === 2 || this.order.site_id === 4),
          tooltip: this.order.site_id === 2 ? '采购时的运费，修改运费需要用户确认，如果是货到付款运费请采购后找用户补' : ''
        },
        // exchange_rate: { label: '汇率', value: this.order.exchange_rate, formType: 'number', attrs: { step: 0.0001 } },
        remark: {
          label: '备注',
          value: this.order.remark,
          required: false,
          max: 500,
          formType: 'textarea'
        }
      };
      this.formAction = 'order/edit';
      this.$refs.wDialogForm.visible = true;
    },
    toCancel() {
      this.$confirm('确认取消吗？已支付金额会原路退回', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('再次确认取消吗？已支付金额会原路退回', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$w_fun.post(`order/cancel`, {
            id: this.order.id
          }).then(res => {
            this.getList();
          });
        }).catch(() => {});
      }).catch(() => {});
    },
    auctionsPay() {
      this.form = {
        id: {
          show: false,
          value: this.order.id
        },
        freeze_amount: {
          label: '预授权金额',
          value: this.order.freeze_amount,
          readonly: true
        },
        amount: {
          label: '订单金额',
          value: this.order.amount,
          attrs: {
            disabled: true
          }
        },
        desc: {
          label: '说明',
          value: '扣除核算金额后，剩余部分会解冻，如果核算金额超过授权金额，会全额扣款，差额可以手动添加支付单',
          readonly: true
        }
      };
      this.formAction = 'order/auctionsPay';
      this.$refs.wDialogForm.visible = true;
    },
    async setPackageForm({
      id,
      order_id,
      third_seller_id
    }) {
      const {
        data
      } = await this.$w_fun.post(`orderGoods/select`, {
        site_id_match: this.order.site_id,
        third_seller_id_match: third_seller_id,
        status_match: 1,
        id_unmatch: id
      });
      const ids = id ? [id] : this.orderGoods.map(i => i.id);
      this.form = {
        order_id: {
          show: false,
          value: order_id
        },
        order_goods_id: {
          value: ids,
          label: '商品',
          formType: 'select',
          options: this.orderGoods.map(i => {
            return {
              label: i.title,
              value: i.id
            };
          }),
          attrs: {
            multiple: true
          }
        },
        third_order_no: {
          label: '平台订单',
          value: '',
          required: false,
          tooltip: '第三方平台的订单号'
        },
        tracking_company: {
          label: '快递公司',
          value: '',
          required: false
        },
        tracking_no: {
          label: '快递单号',
          value: '',
          required: false
        },
        from_address: {
          label: '发货地址',
          value: '',
          required: false
        },
        purchase_at: {
          label: '采购时间',
          value: '',
          formType: 'datePicker',
          required: false,
          attrs: {
            type: 'datetime'
          }
        },
        together_goods_ids: {
          show: !!third_seller_id && data.list && data.list.length > 0,
          value: [],
          label: '同捆商品',
          formType: 'select',
          options: data.list,
          attrs: {
            multiple: true
          },
          required: false
        },
        remark: {
          label: '备注',
          value: '',
          required: false,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = 'orderPackage/add';
      this.$refs.wDialogForm.visible = true;
    },
    editGoods(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        title: {
          label: '名称',
          value: row.title
        },
        link: {
          label: '链接',
          value: row.link
        },
        price: {
          value: row.price,
          label: '单价',
          formType: 'number',
          attrs: {
            append: '円'
          },
          width: '45%'
        },
        quantity: {
          value: row.quantity,
          label: '数量',
          formType: 'number',
          attrs: {
            step: 1
          },
          width: '45%'
        },
        addition_id: {
          value: row.addition_id,
          label: '验货拍照',
          formType: 'select',
          options: '/addition/select?position_type_byte=1,2',
          required: false
        },
        addition_comment: {
          value: row.addition_comment,
          label: '拍照备注',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          required: false
        },
        thumb: {
          label: '图片',
          value: row.thumb ? [row.thumb] : [],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg'
          }
        },
        desc: {
          value: '变更商品金额需要用户确认',
          label: '说明',
          readonly: true
        }
      };
      if (row && row.id) {
        this.form.id = {
          show: false,
          value: row.id
        };
        this.formAction = 'orderGoods/edit';
      } else {
        this.formAction = 'orderGoods/add';
      }
      this.$refs.wDialogForm.visible = true;
    },
    toDelGoods(row) {
      this.$confirm('确认删除吗？删除会变更订单金额，需要用户确认', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderGoods/edit`, {
          id: row.id,
          title: row.title,
          link: row.link,
          price: row.price,
          quantity: 0
        }).then(res => {
          this.loadDataList('orderChanges');
        });
      }).catch(() => {});
    },
    toDelGoodsChange(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderGoods/delChange`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderChanges');
        });
      }).catch(() => {});
    },
    toSendChange(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        remark: {
          label: '变动说明',
          value: '',
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = 'order/sendChange';
      this.$refs.wDialogForm.visible = true;
    },
    toConfirmChange(row) {
      this.$confirm('确认帮用户确认吗？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/confirmChange`, {
          id: row.id
        }).then(res => {
          this.getList();
          this.loadDataList('orderChanges');
        });
      }).catch(() => {});
    },
    toCancelChange(row) {
      this.$confirm('确认取消吗？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`order/cancelChange`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderChanges');
          this.getList();
        });
      }).catch(() => {});
    },
    toPurchase(row) {
      this.setPackageForm(row);
    },
    async addTogether(row) {
      const {
        data
      } = await this.$w_fun.post(`orderGoods/select`, {
        site_id_match: this.order.site_id,
        third_seller_id_match: row.orderGoods[0].third_seller_id,
        status_match: 1,
        id_unmatch: row.id
      });
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        together_goods_ids: {
          value: [],
          label: '同捆商品',
          formType: 'select',
          options: data.list,
          attrs: {
            multiple: true
          }
        }
      };
      this.formAction = 'orderPackage/addTogether';
      this.$refs.wDialogForm.visible = true;
    },
    toOrder(id) {
      const {
        href
      } = this.$router.resolve({
        name: 'OrderGet',
        query: {
          id
        }
      });
      window.open(href, '_blank');
    },
    async delTogether(row) {
      const confirm = await this.$confirm('确定要取消同捆吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`orderPackage/cancelTogether`, {
        id: row.id
      }).then(res => {
        this.loadDataList('orderPackages');
      });
    },
    addPackage() {
      this.setPackageForm({
        order_id: this.order.id
      });
    },
    async deletePackage(row) {
      const confirm = await this.$confirm('确认删除吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`orderPackage/delete`, {
        id: row.id
      }).then(res => {
        this.loadDataList('orderPackages');
        this.getList();
      });
    },
    async editAddition(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        addition_id: {
          value: row.addition_id,
          label: '验货拍照',
          formType: 'select',
          options: '/addition/select?position_type_byte=1,2',
          methods: {
            change: async v => {
              if (v) {
                this.getForm().model.addition_amount = await this.getAdditionAmount();
              } else {
                this.getForm().model.addition_amount = 0;
              }
            }
          }
        },
        goods_quantity: {
          label: '商品数量',
          value: row.goods_quantity,
          formType: 'number',
          methods: {
            change: async v => {
              if (this.getForm().model.addition_id) {
                this.getForm().model.addition_amount = await this.getAdditionAmount();
              } else {
                this.getForm().model.addition_amount = 0;
              }
            }
          }
        },
        addition_amount: {
          label: '验证拍照费用',
          value: row.addition_amount,
          formType: 'amount',
          attrs: {
            exchange_rate: this.order.exchange_rate
          }
        },
        addition_comment: {
          label: '备注',
          value: row.addition_comment,
          required: false
        }
      };
      this.formAction = 'orderPackage/edit';
      this.$refs.wDialogForm.visible = true;
    },
    async getAdditionAmount() {
      const {
        data
      } = await this.$w_fun.post(`addition/amount`, {
        additions: [{
          addition_id: this.getForm().model.addition_id,
          goods_quantity: this.getForm().model.goods_quantity
        }],
        exchange_rate: this.order.exchange_rate
      });
      return data ? data.amount : 0;
    },
    addWork(row) {
      this.$refs.workAddDialog.open({
        obj_id: row.id,
        obj_title: '包裹：' + row.package_no,
        work_type_id: 2,
        desc: '验货拍照，' + row.addition.title + '，' + row.addition_comment
      });
    },
    toPay(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        fields: {
          label: '支付内容',
          value: [],
          formType: 'checkbox',
          options: []
        }
      };
      if (row.trans_amount > 0 && row.trans_pay_status === 0) {
        this.form.fields.options.push({
          label: '运费：' + row.trans_amount + '元',
          value: 'trans_amount'
        });
      }
      if (row.addition_amount > 0 && row.addition_pay_status === 0) {
        this.form.fields.options.push({
          label: '验货拍照：' + row.addition_amount + '元',
          value: 'addition_amount'
        });
      }
      this.formAction = 'orderPackage/toPay';
      this.$refs.wDialogForm.visible = true;
    },
    editPurchase(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        third_order_no: {
          value: row.third_order_no,
          label: '平台订单号'
        },
        purchase_at: {
          label: '采购时间',
          value: row.purchase_at,
          formType: 'datePicker',
          required: false,
          attrs: {
            type: 'datetime'
          }
        },
        remark: {
          label: '备注',
          value: '',
          required: false,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = 'orderPackage/edit';
      this.$refs.wDialogForm.visible = true;
    },
    async editTrans(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        tracking_company: {
          label: '快递公司',
          value: row.tracking_company,
          required: false
        },
        tracking_no: {
          label: '快递单号',
          value: row.tracking_no,
          required: false
        },
        from_address: {
          label: '发货地址',
          value: row.from_address,
          required: false
        },
        trans_amount: {
          label: '运费',
          value: row.trans_amount,
          required: false,
          formType: 'amount',
          attrs: {
            exchange_rate: this.order.exchange_rate
          },
          tooltip: '货到付款的运费'
        }
      };
      this.formAction = 'orderPackage/edit';
      this.$refs.wDialogForm.visible = true;
    },
    editPackage(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        package_no: {
          label: '包裹号',
          value: row.package_no,
          required: true
        },
        size_type: {
          value: row.size_type,
          label: '包裹大小',
          formType: 'radio',
          options: '/orderPackage/sizeType'
        },
        weight: {
          label: '重量',
          value: row.weight,
          required: false,
          formType: 'number',
          attrs: {
            append: 'kg'
          }
        }
      };
      this.formAction = 'orderPackage/edit';
      this.$refs.wDialogForm.visible = true;
    },
    addPay() {
      this.$refs.payTable.addPay({
        order_id: {
          show: false,
          value: this.order.id
        },
        order_package_id: {
          label: '包裹',
          value: undefined,
          show: this.order.status >= 4,
          formType: 'radio',
          options: this.orderPackages.map(i => {
            return {
              label: i.package_no,
              value: i.id
            };
          })
        }
      }, this.order.exchange_rate);
    },
    formDone() {
      switch (this.formAction) {
        case 'order/edit':
        case 'order/auctionsPay':
        case 'order/sendChange':
          this.getList();
          this.loadDataList('orderChanges');
          break;
        case 'orderGoods/edit':
        case 'orderGoods/add':
          this.loadDataList('orderChanges');
          break;
        case 'orderPackage/add':
          this.loadDataList('orderGoods');
          this.loadDataList('orderPackages');
          break;
        case 'orderPackage/edit':
        case 'orderPackage/toPay':
        case 'orderPackage/addTogether':
          this.loadDataList('orderPackages');
          break;
        case 'orderRefund/verify':
        case 'orderRefund/add':
          this.loadDataList('orderRefunds');
          break;
        case 'orderPay/add':
          this.loadDataList('orderPays');
      }
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};