import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import OrderRefundAddDialog from '@/views/orderRefund/dialog/orderRefundAddDialog';
import WorkAddDialog from '@/views/work/dialog/workAddDialog';
import RefundTable from '@/views/orderRefund/refund';
import PayTable from '@/views/orderPay/pay';
import PrintRecipt from '@/components/Print/PrintRecipt';
export default {
  title: 'OrderCombineGet',
  components: {
    PayTable,
    WorkAddDialog,
    OrderRefundAddDialog,
    RefundTable,
    PrintRecipt
  },
  data() {
    return {
      module: 'orderCombine',
      activeNames: ['orderCombine', 'orderCombinePackages', 'orderPackages'],
      oldActiveNames: ['orderCombine', 'orderCombinePackages', 'orderPackages'],
      orderCombine: {
        id: undefined,
        parent_id: 0
      },
      orderCombinePackages: [],
      orderPackages: [],
      histories: [],
      photos: [],
      orderPays: [],
      orderRefunds: [],
      userAddressList: [],
      formOrderCombine: {},
      loading: false,
      form: {},
      formAddress: {},
      formAction: '',
      formActionAddress: '',
      title: '修改',
      labelWidth: '120px',
      additionAmount: 0
    };
  },
  computed: {
    size_() {
      return this.$store.getters['size'];
    }
  },
  created() {
    this.orderCombine.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    getList() {
      this.$w_fun.post(`${this.module}/get`, {
        id: this.orderCombine.id
      }).then(res => {
        this.orderCombine = res.data.detail;
        this.additionAmount = res.data.additionAmount;
        this.userAddressList = this.orderCombine.orderCombineAddresses || [];
        this.setOrderForm(this.orderCombine);
        this.loadDataList('orderPackages');
        this.loadDataList('orderCombinePackages');
        if (this.$refs.wDialogForm.visible) {
          this.getForm().options.order_combine_address_id = this.userAddressList.map(i => {
            return {
              label: i.consignee + '(' + i.phone + ')',
              value: i.id
            };
          });
        }
      });
    },
    setOrderForm({
      id,
      user_id,
      paid_at,
      created_at,
      delivery_at,
      amount,
      trans_amount,
      addition_amount,
      refund,
      discount,
      origin_amount,
      fee,
      userCoupons,
      exchange_rate,
      cost,
      store_amount,
      paid_amount,
      status_text,
      user_status,
      pay_status,
      order_no,
      comment,
      remark,
      other_amount,
      other_amount_title,
      user = {},
      transFee = {}
    }) {
      this.formOrderCombine = {
        order_no: {
          label: '订单号',
          value: order_no,
          copy: true
        },
        status: {
          label: '订单状态',
          value: status_text,
          width: '75%'
        },
        user_id: {
          label: '会员专属码',
          value: user_id,
          copy: true
        },
        phone: {
          label: '手机',
          value: user.phone,
          copy: true
        },
        nickname: {
          label: '昵称',
          value: user.nickname,
          width: '50%'
        },
        created_at: {
          label: '下单时间',
          value: created_at
        },
        paid_at: {
          label: '支付时间',
          value: paid_at
        },
        delivery_at: {
          label: '发出时间',
          value: delivery_at
        },
        amount: {
          label: '金额',
          value: amount
        },
        paid_amount: {
          label: '已支付金额',
          value: paid_amount
        },
        origin_amount: {
          label: '原始金额',
          value: origin_amount
        },
        trans_amount: {
          label: '运费',
          value: trans_amount
        },
        addition_amount: {
          label: '增值服务费',
          value: addition_amount
        },
        store_amount: {
          label: '超期存储费',
          value: store_amount
        },
        other_amount: {
          label: other_amount_title,
          value: other_amount,
          show: other_amount > 0,
          tooltip: '其他费用'
        },
        discount: {
          label: '折扣金额',
          value: discount,
          tooltip: discount > 0 && userCoupons ? userCoupons.map(i => i.title).join(',') : ''
        },
        fee: {
          label: '手续费',
          value: fee
        },
        refund: {
          label: '退款金额',
          value: refund
        },
        exchange_rate: {
          label: '汇率',
          value: exchange_rate,
          width: '75%'
        },
        comment: {
          label: '用户留言',
          value: comment,
          width: '50%'
        },
        remark: {
          label: '备注',
          value: remark,
          width: '50%'
        },
        trans_fee: {
          label: '运输渠道',
          value: transFee && transFee.title
        }
      };
    },
    handleChange(v) {
      if (this.oldActiveNames.length < v.length) {
        const f = v.filter(i => !this.oldActiveNames.includes(i));
        for (const item of f) {
          if (item !== 'orderCombine') {
            this.loadDataList(item);
          } else {
            this.getList();
          }
        }
      }
      this.oldActiveNames = v;
    },
    loadDataList(v) {
      if (this.activeNames.indexOf(v) === -1) {
        this.oldActiveNames.push(v);
        this.activeNames.push(v);
      }
      if (v === 'orderRefunds') {
        this.$refs.refundTable.getList();
        return;
      }
      if (v === 'orderPays') {
        this.$refs.payTable.getList();
        return;
      }
      this.$w_fun.post(`${this.module}/${v}`, {
        id: this.orderCombine.id
      }).then(res => {
        this[v] = res.data.list;
      });
    },
    editOrderCombine() {
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        },
        trans_fee_category_id: {
          label: '一级运输渠道',
          value: this.orderCombine.transFee ? this.orderCombine.transFee.trans_fee_category_id : undefined,
          formType: 'select',
          options: 'transFeeCategory/select',
          methods: {
            change: async v => {
              this.getForm().model.trans_fee_id = undefined;
              const {
                data
              } = await this.$w_fun.post('transFee/select?trans_fee_category_id_match=' + v);
              this.getForm().options.trans_fee_id = data.list;
            }
          }
        },
        trans_fee_id: {
          label: '二级运输渠道',
          value: this.orderCombine.trans_fee_id,
          formType: 'select',
          options: 'transFee/select?trans_fee_category_id_match=' + (this.orderCombine.transFee ? this.orderCombine.transFee.trans_fee_category_id : 0)
        },
        remark: {
          label: '备注',
          value: this.orderCombine.remark,
          required: false,
          max: 500,
          formType: 'textarea'
        }
      };
      this.formAction = 'orderCombine/edit';
      this.$refs.wDialogForm.visible = true;
    },
    async toPay() {
      const {
        data
      } = await this.$w_fun.post('orderCombine/storeAmount', {
        id: this.orderCombine.id,
        exchange_rate: this.orderCombine.exchange_rate
      });
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        },
        // exchange_rate: { label: '汇率', value: this.orderCombine.exchange_rate, formType: 'number', attrs: { step: 0.0001 } },
        fee: {
          label: '手续费',
          value: this.orderCombine.fee,
          formType: 'amount'
        },
        store_amount: {
          label: '超期存储费',
          value: data ? data.amount : 0,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          required: false,
          methods: {
            change: v => {
              this.calcAmount();
            }
          }
        },
        other_amount: {
          label: '其他费用',
          value: this.orderCombine.other_amount,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          required: false,
          methods: {
            change: v => {
              this.calcAmount();
            }
          }
        },
        other_amount_title: {
          label: '其他费用名称',
          value: '',
          show: m => m.other_amount > 0
        }
      };
      let i = 1;
      for (const item of this.orderCombinePackages) {
        this.form['order_combine_package_' + i] = {
          label: '捆包' + i,
          value: `增值服务：${item.addition_amount}元，运费：${item.trans_amount}元，体积重：${item.v_w}kg，重量：${item.weight}kg`,
          readonly: true
        };
      }
      this.form.total = {
        label: '合计',
        value: 0,
        readonly: true
      };
      this.form.tips = {
        label: '',
        value: '核算并发送给用户支付',
        readonly: true
      };
      this.formAction = 'orderCombine/toPay';
      this.$refs.wDialogForm.visible = true;
      this.$nextTick(() => {
        this.calcAmount();
      });
    },
    async toCancel() {
      const {
        data
      } = await this.$w_fun.post('orderCombine/getCancelAmount', {
        exchange_rate: this.orderCombine.exchange_rate
      });
      const amount = data && data.amount ? data.amount : 0;
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        },
        amount: {
          label: '取消费用',
          value: amount,
          formType: 'amount'
        },
        remark: {
          label: '备注',
          value: '',
          required: false,
          max: 500
        },
        package_ids: {
          label: '重新入库包裹',
          value: [],
          required: false,
          formType: 'packageIds'
        }
      };
      this.formAction = 'orderCombine/cancel';
      this.$refs.wDialogForm.visible = true;
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.wPackagesSelectTable.onRefresh();
    },
    calcAmount() {
      this.$w_fun.post(`orderCombine/amount`, {
        id: this.orderCombine.id,
        store_amount: this.getForm().model.store_amount,
        other_amount: this.getForm().model.other_amount
      }).then(res => {
        this.getForm().model.total = res.data.amount + '元';
      });
    },
    toHandle() {
      this.$confirm('确认受理吗？', '受理提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`${this.module}/handle`, {
          id: this.orderCombine.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    editAddress({
      id,
      consignee,
      phone,
      id_card_no,
      province_id,
      city_id,
      region_id,
      detail,
      id_card_front,
      id_card_back
    }) {
      this.formAddress = {
        order_combine_id: {
          value: this.orderCombine.id,
          show: false
        },
        consignee: {
          label: '收件人',
          value: consignee
        },
        phone: {
          label: '电话',
          value: phone
        },
        id_card_no: {
          label: '身份证',
          value: id_card_no
        },
        location: {
          label: '地区',
          value: region_id ? [province_id, city_id, region_id] : [],
          formType: 'cascader',
          options: {
            url: '/address/select',
            nodeKey: 'pid_match'
          },
          lazy: true
        },
        detail: {
          label: '详细地址',
          value: detail
        },
        id_card_front: {
          label: '身份证正面',
          value: [{
            path: id_card_front,
            url: id_card_front
          }],
          formType: 'upload',
          attrs: {
            drag: true,
            accept: '.jpg,.png,.jpeg'
          },
          type: 'array',
          required: false
        },
        id_card_back: {
          label: '身份证正面',
          value: [{
            path: id_card_back,
            url: id_card_back
          }],
          formType: 'upload',
          attrs: {
            drag: true,
            accept: '.jpg,.png,.jpeg'
          },
          type: 'array',
          required: false
        }
      };
      if (id) {
        this.formAddress.id = {
          show: false,
          value: id
        };
        this.formActionAddress = 'orderCombineAddress/edit';
      } else {
        this.formActionAddress = 'orderCombineAddress/add';
      }
      this.$refs.wAddressDialogForm.visible = true;
    },
    deleteAddress(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombineAddress/delete`, {
          id: row.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    editReport({
      id,
      goods_cate_id,
      quantity,
      amount
    }) {
      this.form = {
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        },
        goods_cate_id: {
          label: '商品分类',
          value: goods_cate_id,
          formType: 'select',
          options: 'adminSetting/select?module_match=orderCombineReportCategory'
        },
        quantity: {
          label: '数量',
          value: quantity
        },
        amount: {
          label: '金额',
          value: amount
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = 'orderCombine/editReport';
      } else {
        this.formAction = 'orderCombine/addReport';
      }
      this.$refs.wDialogForm.visible = true;
    },
    delReport(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombine/deleteReport`, {
          id: row.id
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    async editAddition() {
      const {
        data
      } = await this.$w_fun.post('addition/combine');
      this.form = {
        id: {
          show: false,
          value: this.orderCombine.id
        }
      };
      for (const cat of data.list) {
        const ids = this.orderCombine.orderCombineAdditions.map(i => i.addition_id);
        const value = cat.children.filter(i => ids.indexOf(i.id) > -1).map(i => i.id);
        this.form['addition_category_id_' + cat.id] = {
          label: cat.title,
          value: cat.is_multiple ? value : value.length > 0 ? value[0] : undefined,
          formType: cat.is_multiple ? 'checkbox' : 'radio',
          options: cat.children.map(i => {
            return {
              label: i.title,
              value: i.id
            };
          }),
          attrs: {
            isButton: true,
            clearable: true
          },
          required: false
        };
        for (const item of cat.children) {
          this.form['addition_' + item.id] = {
            label: item.title + '备注',
            value: this.orderCombine.orderCombineAdditions.filter(i => i.addition_id === item.id).map(i => i.comment).join(''),
            show: m => {
              if (item.is_comment) {
                if (cat.is_multiple) {
                  return m['addition_category_id_' + cat.id].indexOf(item.id) > -1;
                } else {
                  return m['addition_category_id_' + cat.id] === item.id;
                }
              }
              return false;
            },
            max: 500,
            required: false
          };
        }
      }
      this.formAction = 'orderCombine/editAdditions';
      this.$refs.wDialogForm.visible = true;
    },
    setOrderCombinePackageForm({
      id,
      tracking_no,
      order_combine_address_id,
      trans_amount,
      addition_amount,
      weight,
      v_w,
      trans_fee_id,
      transFee = {},
      l,
      w,
      h
    }) {
      weight = weight || this.orderPackages.reduce((a, b) => a + b.weight, 0);
      this.form = {
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        },
        // order_package_ids: {
        //   label: '包裹',
        //   value: id ? this.orderPackages.filter(i => i.order_combine_package_id === id).map(i => i.package_no): [],
        //   formType: 'select',
        //   options: this.orderPackages.filter(i => i.order_combine_package_id === 0).map(i => {return { label: i.package_no, value: i.id }}),
        //   attrs: {
        //     multiple: true
        //   }
        // },
        order_combine_address_id: {
          label: '收货地址',
          value: order_combine_address_id,
          formType: 'select',
          options: this.userAddressList.map(i => {
            return {
              label: i.consignee + '(' + i.phone + ')',
              value: i.id
            };
          }),
          attrs: {
            quickAdd: () => {
              this.editAddress({});
            }
          }
        },
        tracking_no: {
          label: '物流单号',
          value: tracking_no,
          required: false
        },
        l: this.sizeFormItem('长', l),
        w: this.sizeFormItem('宽', w),
        h: this.sizeFormItem('高', h),
        weight: {
          label: '重量',
          value: weight,
          formType: 'number',
          attrs: {
            append: 'kg'
          },
          required: false,
          width: '45%',
          methods: {
            change: v => {
              this.calcTransAmount();
            }
          }
        },
        v_w: {
          label: '体积重',
          value: v_w,
          formType: 'number',
          required: false,
          width: '45%',
          methods: {
            change: v => {
              this.calcTransAmount();
            }
          }
        },
        trans_fee_category_id: {
          label: '一级运输渠道',
          value: id ? transFee && transFee.trans_fee_category_id : this.orderCombine.transFee.trans_fee_category_id,
          formType: 'select',
          options: 'transFeeCategory/select',
          methods: {
            change: async v => {
              this.getForm().model.trans_fee_id = undefined;
              const {
                data
              } = await this.$w_fun.post('transFee/select?trans_fee_category_id_match=' + v);
              this.getForm().options.trans_fee_id = data.list;
            }
          },
          required: false,
          width: '45%'
        },
        trans_fee_id: {
          label: '二级运输渠道',
          value: id ? trans_fee_id : this.orderCombine.trans_fee_id,
          formType: 'select',
          options: 'transFee/select?trans_fee_category_id_match=' + (id ? transFee && transFee.trans_fee_category_id : this.orderCombine.transFee.trans_fee_category_id),
          required: false,
          width: '45%',
          methods: {
            change: v => {
              this.calcVW();
            }
          }
        },
        trans_amount: {
          label: '运费',
          value: trans_amount,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          min: 0,
          required: false
        },
        addition_amount: {
          label: '增值服务费',
          value: addition_amount || this.additionAmount,
          formType: 'amount',
          attrs: {
            exchange_rate: this.orderCombine.exchange_rate
          },
          min: 0,
          required: false
        },
        remark: {
          label: '备注',
          value: this.orderCombine.remark,
          required: false,
          max: 500
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = 'orderCombinePackage/edit';
      } else {
        this.formAction = 'orderCombinePackage/add';
      }
      this.$refs.wDialogForm.visible = true;
    },
    addOrderCombinePackage() {
      this.setOrderCombinePackageForm({});
    },
    editOrderCombinePackage(row) {
      this.setOrderCombinePackageForm(row);
    },
    sizeFormItem(label, value) {
      return {
        label: label,
        value: value,
        required: false,
        formType: 'number',
        attrs: {
          append: 'cm'
        },
        width: '33%',
        methods: {
          change: v => {
            this.calcVW();
          }
        }
      };
    },
    calcVW() {
      const model = this.getForm().model;
      const options = this.getForm().options.trans_fee_id;
      model.v_w = 0;
      if (options && options.length > 0) {
        for (const o of options) {
          if (o.value === model.trans_fee_id) {
            if (o.v_w_coefficient > 0) {
              model.v_w = Math.round(model.l * model.w * model.h / o.v_w_coefficient);
            }
          }
        }
      }
      this.calcTransAmount();
    },
    calcTransAmount() {
      const model = this.getForm().model;
      if (model.trans_fee_id && (model.weight > 0 || model.v_w > 0)) {
        this.$w_fun.post('transFee/amount', {
          id: model.trans_fee_id,
          weight: model.weight,
          v_w: model.v_w,
          exchangeRate: this.orderCombine.exchange_rate
        }).then(res => {
          model.trans_amount = res.data.amount;
        });
      }
    },
    delOrderCombinePackage(row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`orderCombinePackage/delete`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderCombinePackages');
          this.loadDataList('orderPackages');
        });
      }).catch(() => {});
    },
    reStore(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        package_no: {
          label: '包裹号',
          value: undefined,
          attrs: {
            disabled: true
          },
          required: false
        },
        size_type: {
          label: '包裹大小',
          value: 2,
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          }
        },
        weight: {
          label: '重量',
          value: row.weight,
          formType: 'number',
          attrs: {
            append: 'kg'
          }
        },
        print: {
          label: '',
          value: '',
          formType: 'print',
          show: m => m.package_no,
          required: false
        }
      };
      this.formAction = 'store/addOrderCombinePackage';
      this.$refs.wDialogForm.visible = true;
    },
    reStorePackage(row) {
      this.$confirm('确认重新入库吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`store/reAdd`, {
          id: row.id
        }).then(res => {
          this.loadDataList('orderPackages');
        });
      }).catch(() => {});
    },
    addPay() {
      this.$refs.payTable.addPay({
        order_combine_id: {
          show: false,
          value: this.orderCombine.id
        }
      }, this.orderCombine.exchange_rate);
    },
    async beforeSubmit(model) {
      if (this.formAction === 'orderCombine/editAdditions') {
        const m = {
          additions: [],
          id: model.id
        };
        for (const k in model) {
          if (k.startsWith('addition_category_id_')) {
            if (this.$w_fun.typeOf(model[k]) === 'array') {
              for (const c of model[k]) {
                m.additions.push({
                  id: c,
                  comment: model['addition_' + c] || ''
                });
              }
            } else {
              if (model[k]) {
                m.additions.push({
                  id: model[k],
                  comment: model['addition_' + model[k]] || ''
                });
              }
            }
          }
        }
        return m;
      }
      if (this.formAction === 'orderCombine/cancel') {
        const confirm = await this.$confirm('确定要取消订单吗？', '提示', {
          type: 'warning'
        }).catch(() => {});
        if (!confirm) {
          return false;
        }
      }
      return model;
    },
    formDone(v) {
      var _v$package;
      switch (this.formAction) {
        case 'orderCombine/edit':
        case 'orderCombine/editReport':
        case 'orderCombine/addReport':
        case 'orderCombine/editAdditions':
        case 'orderCombine/toPay':
        case 'orderCombineAddress/edit':
        case 'orderCombineAddress/add':
        case 'orderCombineAddress/delete':
        case 'orderCombine/cancel':
          this.getList();
          break;
        case 'orderCombinePackage/add':
          this.loadDataList('orderPackages');
          this.loadDataList('orderCombinePackages');
          break;
        case 'orderCombinePackage/edit':
          this.loadDataList('orderCombinePackages');
          break;
        case 'orderRefund/verify':
        case 'orderRefund/add':
          this.loadDataList('orderRefunds');
          break;
        case 'store/addOrderCombinePackage':
          this.getForm().model.package_no = v === null || v === void 0 ? void 0 : (_v$package = v.package) === null || _v$package === void 0 ? void 0 : _v$package.package_no;
          this.$refs.wDialogForm.visible = true;
          this.printPackageNo();
          // todo 打印包裹码
          break;
      }
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    printPackageNo() {
      // todo 打印包裹码
      this.$refs.printRecipt.printReceipt();
    }
  }
};