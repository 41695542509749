var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100"
  }, [_c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/审核",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%"
    },
    on: {
      done: v => _vm.$emit("done", v)
    }
  }), _c("AdminSettingAddDialog", {
    ref: "adminSettingAddDialog",
    on: {
      done: function ($event) {
        _vm.getForm().refreshOptions("reason");
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };