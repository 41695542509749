import ArticleCategoryAddDialog from '@/views/articleCategory/dialog/articleCategoryAddDialog';
export default {
  title: 'ArticleAdd',
  components: {
    ArticleCategoryAddDialog
  },
  data() {
    return {
      module: 'article',
      model: {
        id: undefined
      },
      formAction: '',
      form: {},
      loading: false,
      tab: ''
    };
  },
  mounted() {
    this.model.id = this.$route.query.id;
    this.tab = this.$route.query.tab || 'article';
    this.getList();
  },
  methods: {
    getList() {
      if (this.model.id) {
        this.$w_fun.post(`${this.module}/get`, {
          id: this.model.id
        }).then(res => {
          this.model = res.data.detail;
          this.formAction = `${this.module}/edit`;
          this.setForm(this.model);
        });
      } else {
        this.formAction = `${this.module}/add`;
        this.setForm({
          module: this.tab
        });
      }
    },
    setForm({
      id,
      title,
      article_category_id,
      thumb,
      status = 1,
      desc,
      admin_id,
      sort,
      is_index = 0,
      articleContent = {},
      module
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        module: {
          show: false,
          value: module
        },
        title: {
          label: '标题',
          value: title
        },
        article_category_id: {
          label: '分类',
          value: article_category_id,
          formType: 'select',
          options: '/articleCategory/select?module_match=' + module,
          attrs: {
            quickAdd: () => {
              this.$refs.articleCategoryAddDialog.open({
                module: module
              });
            }
          }
        },
        thumb: {
          label: '封面图',
          value: [{
            path: thumb,
            url: thumb
          }],
          formType: 'upload',
          attrs: {
            drag: true,
            accept: '.jpg,.png,.jpeg',
            tip: '建议尺寸：420px * 150px'
          },
          type: 'array',
          required: this.tab === 'article'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        desc: {
          label: '简介',
          value: desc,
          required: false,
          formType: 'textarea'
        },
        sort: {
          label: '排序',
          value: sort,
          formType: 'number',
          required: false
        },
        is_index: {
          label: '是否首页推荐',
          value: is_index,
          formType: 'boolean'
        },
        content: {
          label: '内容',
          value: articleContent && articleContent.content,
          formType: 'editor',
          width: '100%',
          attrs: {
            height: '400px'
          }
        }
      };
    },
    async onSubmit() {
      try {
        this.loading = true;
        await this.$refs.wForm.submit();
        this.loading = false;
        if (!this.model.id) {
          this.$router.go(-1);
        } else {
          this.getList();
        }
      } catch (e) {
        this.loading = false;
      }
    },
    getForm() {
      return this.$refs.wForm;
    }
  }
};