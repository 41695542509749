import "core-js/modules/es.array.push.js";
export default {
  title: 'ArticleIndex',
  data() {
    return {
      module: 'article',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true,
        recycle: {
          autoLink: true
        }
      },
      currentTab: ''
    };
  },
  methods: {
    changeTab(v) {
      this.currentTab = v;
    },
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.$router.push({
        name: 'ArticleAdd',
        query: {
          id: row.id,
          tab: this.currentTab
        }
      });
    },
    onAdd() {
      this.$router.push({
        name: 'ArticleAdd',
        query: {
          tab: this.currentTab
        }
      });
    }
  }
};