export default {
  name: 'TestAuctionsOrder',
  computed: {
    size_() {
      return this.$store.getters['size'];
    }
  },
  data() {
    return {
      module: 'test',
      form: {
        third_goods_id: {
          label: '商品ID',
          value: ''
        },
        third_seller_id: {
          label: '卖家ID',
          value: ''
        },
        site_account_id: {
          label: '账号',
          value: undefined,
          formType: 'select',
          options: '/siteAccount/select?site_id_match=4'
        }
      },
      orderForm: {},
      order: {},
      loading: false,
      refreshCount: 0,
      goods: {}
    };
  },
  methods: {
    async onSubmit() {
      await this.getForm().validate();
      const data = this.getForm().model;
      this.$w_fun.post(`${this.module}/auctionsOrder`, data).then(res => {
        this.order = res.data.detail || {};
        this.setOrderForm();
      });
    },
    setOrderForm() {
      this.orderForm = {
        order_id: {
          label: '订单号',
          value: this.order.order_id
        },
        express_id: {
          label: '快递单号',
          value: this.order.express_id
        }
      };
    },
    refreshGoods() {
      this.refreshCount += 1;
      this.$w_fun.post(`${this.module}/getAuctions`, {
        third_goods_id: this.getForm().model.third_goods_id
      }).then(res => {
        this.goods = res.data.goods ? res.data.goods : {};
        const model = this.getForm().model;
        if (!model.third_seller_id && this.goods.third_seller_id) {
          model.third_seller_id = this.goods.third_seller_id;
        }
        if (!this.goods.id && this.refreshCount < 3) {
          setTimeout(() => {
            this.refreshGoods();
          }, 3000);
        } else {
          this.refreshCount = 0;
        }
      });
    },
    getForm() {
      return this.$refs.wForm;
    }
  }
};