import "core-js/modules/es.array.push.js";
import WorkAddDialog from './dialog/workAddDialog';
import AssignDialog from './dialog/assignDialog.vue';
export default {
  name: 'WorkIndex',
  components: {
    WorkAddDialog,
    AssignDialog
  },
  data() {
    return {
      module: 'work',
      operates: {},
      currentTab: '',
      rowId: null,
      canMultipleAssign: false,
      canMultipleAccept: false,
      canMultipleDone: false
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    }
  },
  methods: {
    changeTab(v) {
      this.currentTab = v;
      this.operates = {
        del: false,
        look: true,
        add: true,
        edit: false,
        other: [{
          title: '指派',
          type: 'warning',
          show: row => this.canDispatch(row),
          click: row => this.assign(row)
        }, {
          title: '接单',
          type: 'primary',
          show: row => this.canAccept(row),
          click: row => this.accept(row)
        }, {
          title: '完成',
          type: 'default',
          show: row => this.canDone(row),
          click: row => this.done(row)
        }]
      };
    },
    canAccept(row) {
      if (row.status !== 0) return false;
      if (row.handle_admin_department_id !== 0) {
        return this.userInfo && this.userInfo.admin_department_id === row.handle_admin_department_id;
      }
      if (row.handle_admin_id !== 0) {
        return this.userInfo && this.userInfo.id === row.handle_admin_id;
      }
    },
    canDispatch(row) {
      if (row.status === 2 || row.status === 3) return false;
      return this.userInfo && (this.userInfo.id === row.add_admin_id || this.userInfo.admin_department_id === row.add_admin_department_id);
    },
    canDone(row) {
      if (row.status !== 1) return false;
      return this.userInfo && (this.userInfo.id === row.add_admin_id || this.userInfo.admin_department_id === row.add_admin_department_id);
    },
    getList() {
      this.$refs.wTable.getList();
    },
    accept(row) {
      const ids = row ? [row.id] : this.$refs.wTable.$refs.table.selection.filter(i => i.status === 0).map(item => item.id);
      if (ids.length === 0) {
        this.$message.error('没有可接单工单');
        return;
      }
      this.$confirm('确认接单吗？', '接单提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`${this.module}/accept`, {
          id: ids
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    assign(row) {
      const ids = row ? [row.id] : this.$refs.wTable.$refs.table.selection.filter(i => i.status === 0 || i.status === 1).map(i => i.id);
      if (ids.length === 0) {
        this.$message.error('没有可指派工单');
        return;
      }
      this.$refs.assignDialog.open(ids);
    },
    done(row) {
      const ids = row ? [row.id] : this.$refs.wTable.$refs.table.selection.filter(i => i.status === 1).map(i => i.id);
      if (ids.length === 0) {
        this.$message.error('没有可完成工单');
        return;
      }
      this.$confirm('确认完成吗？', '完成提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$w_fun.post(`${this.module}/done`, {
          id: ids
        }).then(res => {
          this.getList();
        });
      }).catch(() => {});
    },
    onAdd() {
      this.$refs.workAddDialog.open({});
    },
    onLook(row) {
      this.$router.push({
        name: 'WorkGet',
        query: {
          id: row.id
        }
      });
    },
    selectChange() {
      this.canMultipleAccept = this.$refs.wTable.$refs.table.selection.filter(i => i.status === 0).length > 0;
      this.canMultipleAssign = this.$refs.wTable.$refs.table.selection.filter(i => i.status === 0 || i.status === 1).length > 0;
      this.canMultipleDone = this.$refs.wTable.$refs.table.selection.filter(i.status === 1).length > 0;
    }
  }
};