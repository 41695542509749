export default {
  name: 'InsteadPayTypeAddDialog',
  data() {
    return {
      module: 'insteadPayType',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      title,
      status,
      fee,
      currency
    }) {
      this.form = {
        title: {
          label: '名称',
          value: title
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        fee: {
          label: '手续费',
          value: fee,
          formType: 'number'
        },
        currency: {
          label: '货币类型',
          value: currency,
          formType: 'radio',
          options: '/siteConfig/currency'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};