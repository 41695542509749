var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("w-form", {
    attrs: {
      title: "添加/编辑",
      form: _vm.formWork,
      readonly: true,
      itemWidth: "25%",
      labelWidth: "100px"
    }
  }), _vm.contents.length ? _c("div", {
    staticClass: "w100 p15 radius3 mt15",
    staticStyle: {
      "max-height": "500px",
      "overflow-y": "auto",
      "overflow-x": "hidden",
      border: "2px solid #aaa"
    }
  }, _vm._l(_vm.contents, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "w100 m10 flex border-f1f1f1-bottom"
    }, [_c("div", {
      staticClass: "w20 p5 tr"
    }, [_c("p", {
      staticClass: "w100 tr p5 f14"
    }, [_vm._v(" " + _vm._s(item.admin.adminDepartment ? item.admin.adminDepartment + " / " : "") + " " + _vm._s(item.admin.nickname) + "：")]), _c("p", {
      staticClass: "w100 tr p5"
    }, [_vm._v(_vm._s(item.created_at) + " ")])]), _c("div", {
      staticClass: "w80 p5 f14"
    }, [_c("p", {
      staticClass: "p5"
    }, [_vm._v(_vm._s(item.content))]), _c("div", {
      staticClass: "w100"
    }, _vm._l(item.files, function (img, index) {
      return _c("el-image", {
        key: index,
        staticClass: "w-image-70 ml5",
        attrs: {
          "initial-index": index,
          src: img,
          "preview-src-list": item.files
        }
      });
    }), 1)])]);
  }), 0) : _vm._e(), _c("w-form", {
    ref: "wForm",
    staticClass: "mt20",
    attrs: {
      title: "回复",
      form: _vm.form,
      action: "work/content",
      labelWidth: "120px"
    }
  }), _c("AssignDialog", {
    ref: "assignDialog",
    on: {
      done: _vm.getList
    }
  }), _c("div", {
    staticClass: "bottom-operation"
  }, [_vm.work.status === 0 || _vm.work.status === 1 ? _c("el-button", {
    attrs: {
      loading: _vm.loading,
      size: "small",
      type: "warning"
    },
    on: {
      click: _vm.assign
    }
  }, [_vm._v("指派")]) : _vm._e(), _c("el-button", {
    attrs: {
      loading: _vm.loading,
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.reply
    }
  }, [_vm._v("提交")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };