export default {
  name: 'TestMercari',
  computed: {
    size_() {
      return this.$store.getters['size'];
    }
  },
  data() {
    return {
      module: 'test',
      form: {
        third_goods_id: {
          label: '商品ID',
          value: ''
        },
        address_code: {
          label: '地址标识',
          value: '2222'
        }
      },
      cartForm: {},
      cart: {
        id: undefined
      },
      loading: false
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        const result = await this.$refs.wForm.submit();
        this.loading = false;
        if (result && result.data && result.data.id) {
          this.cart.id = result.data.id;
          setTimeout(() => {
            this.refreshGoods();
          }, 5000);
        }
      } catch (e) {
        this.loading = false;
      }
    },
    setCartForm() {
      this.cartForm = {
        third_goods_id: {
          label: '商品ID',
          value: this.cart.third_goods_id
        },
        third_seller_id: {
          label: '卖家ID',
          value: this.cart.third_seller_id
        },
        title: {
          label: '商品标题',
          value: this.cart.title
        },
        price: {
          label: '价格',
          value: this.cart.price
        },
        description: {
          label: '详情',
          value: this.cart.goodsInfo.description
        },
        trx_id: {
          label: '订单号',
          value: this.cart.body && this.cart.body.trx_id
        }
      };
    },
    refreshGoods() {
      this.$w_fun.post(`${this.module}/getMercariProduct`, {
        third_goods_id: this.getForm().model.third_goods_id
      }).then(res => {
        this.cart = res.data ? res.data : {};
        this.setCartForm();
      });
    },
    order() {
      let model = this.getForm().model;
      this.$w_fun.post(`${this.module}/mercari`, {
        third_goods_id: model.third_goods_id,
        address_code: model.address_code
      }).then(res => {
        this.cart = res.data.detail ? res.data.detail : {};
        this.setCartForm();
      });
    },
    getForm() {
      return this.$refs.wForm;
    }
  }
};