export default {
  name: 'WorkAddDialog',
  data() {
    return {
      module: 'work',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      desc,
      handle_admin_department_id,
      handle_admin_id,
      obj_id,
      work_type_id,
      priority_id,
      is_user_visible = 0,
      obj_title,
      user_desc
    }) {
      this.form = {
        desc: {
          label: '简要描述',
          value: desc,
          max: 255,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        },
        work_type_id: {
          label: '工单类型',
          value: work_type_id,
          formType: 'select',
          options: {
            url: '/work/create',
            key: 'workType'
          },
          methods: {
            change: v => {
              this.$set(this.form.obj_id.options, 'data', {
                work_type_id: v
              });
              this.getForm().refreshOptions('obj_id');
            }
          },
          show: !id && !obj_title
        },
        work_type_id_text: {
          label: '工单类型',
          value: work_type_id,
          formType: 'select',
          options: {
            url: '/work/create',
            key: 'workType'
          },
          readonly: true,
          show: !!id || obj_title
        },
        obj_id: {
          show: m => m.work_type_id !== 99 && !id && !obj_title,
          label: '关联数据',
          value: obj_id,
          formType: 'remote',
          options: {
            url: '/work/objSelect',
            data: {
              work_type_id: work_type_id
            }
          },
          readonlyValue: obj_title
        },
        obj_title: {
          label: '关联数据',
          value: obj_title,
          show: m => m.work_type_id !== 99 && obj_title,
          readonly: true
        },
        handle_admin_id: {
          label: '接单人',
          value: handle_admin_id,
          formType: 'select',
          options: '/admin/select',
          required: false
        },
        handle_admin_department_id: {
          label: '接单部门',
          value: handle_admin_department_id,
          formType: 'treeRadioFree',
          options: '/adminDepartment/selectTree',
          required: false
        },
        priority_id: {
          label: '优先级',
          value: priority_id,
          formType: 'select',
          options: {
            url: '/work/create',
            key: 'priority'
          }
        },
        is_user_visible: {
          show: false,
          label: '用户是否可见',
          value: is_user_visible,
          formType: 'boolean',
          attrs: {
            isButton: true
          }
        },
        user_desc: {
          label: '用户显示内容',
          value: user_desc,
          max: 255,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          show: m => m.is_user_visible
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};