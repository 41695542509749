import "core-js/modules/es.array.push.js";
import { getToken } from '@/utils/auth';
export default {
  name: 'OrderCombineImportDialog',
  computed: {
    size_() {
      return this.$store.getters['size'];
    },
    token_() {
      return getToken();
    }
  },
  data() {
    return {
      module: 'orderCombine',
      visible: false,
      result: null,
      activeNames: [],
      list: [{
        name: 'success',
        title: '成功',
        color: ''
      }, {
        name: 'replace',
        title: '替换',
        color: ''
      }, {
        name: 'ignore',
        title: '忽略',
        color: '',
        tip: '(数据无变化)'
      }, {
        name: 'fail',
        title: '失败',
        color: 'red',
        tip: '(未找到对应捆包)'
      }]
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    formDone({
      data
    }) {
      this.result = data;
      if (this.result && this.result.fail && this.result.fail.length > 0) {
        this.activeNames.push('fail');
      }
    },
    done() {
      this.visible = false;
      if (this.result) {
        this.$emit('done');
      }
    }
  }
};