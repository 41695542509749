var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-collapse", {
    attrs: {
      size: _vm.size_
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "基础信息",
      name: "orderCombine"
    }
  }, [_c("w-form", {
    ref: "wForm",
    attrs: {
      title: "订单信息",
      form: _vm.formOrderCombine,
      readonly: true,
      itemWidth: "25%",
      labelWidth: "100px"
    }
  }), _c("el-button-group", [_vm.orderCombine.status === 0 ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.toHandle
    }
  }, [_vm._v("受理")]) : _vm._e(), _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.editOrderCombine
    }
  }, [_vm._v("修改")]), _vm.orderCombine.pay_status === 0 ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "warning",
      size: _vm.size_
    },
    on: {
      click: _vm.toPay
    }
  }, [_vm._v("核算")]) : _vm._e(), _vm.orderCombine.status !== 99 && _vm.orderCombine.status !== 6 ? _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "danger",
      size: _vm.size_
    },
    on: {
      click: _vm.toCancel
    }
  }, [_vm._v("取消")]) : _vm._e()], 1), _c("p", {
    staticClass: "w100 p5 b mt10 border-f1f1f1-top"
  }, [_vm._v("收货地址:")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.editAddress({});
      }
    }
  }, [_vm._v("新增")]), _c("el-table", {
    staticClass: "ml40",
    attrs: {
      data: _vm.userAddressList,
      "show-header": false,
      size: _vm.size_,
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "consignee",
      label: "收件人",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.consignee,
            expression: "scope.row.consignee"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.consignee))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "phone",
      label: "电话",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.phone,
            expression: "scope.row.phone"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.phone))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id_card_no",
      label: "身份证",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.id_card_no,
            expression: "scope.row.id_card_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.id_card_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "location",
      label: "区域",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.location,
            expression: "scope.row.location"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.location))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "detail",
      label: "详细地址",
      width: "240",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.detail,
            expression: "scope.row.detail"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.detail))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id_card_no",
      label: "身份证正面",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-image", {
          staticClass: "w-image-70 ml5",
          attrs: {
            src: scope.row.id_card_front,
            "preview-src-list": [scope.row.id_card_front, scope.row.id_card_back],
            fit: "contain"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id_card_no",
      label: "身份证反面",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-image", {
          staticClass: "w-image-70 ml5",
          attrs: {
            src: scope.row.id_card_front,
            "preview-src-list": [scope.row.id_card_front, scope.row.id_card_back],
            "z-index": 1,
            fit: "contain"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editAddress(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.deleteAddress(scope.row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1), _vm.orderCombine.orderCombineReports && _vm.orderCombine.orderCombineReports.length > 0 ? [_c("p", {
    staticClass: "w100 p5 b mt10 border-f1f1f1-top"
  }, [_vm._v("申报信息:")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: function ($event) {
        return _vm.editReport({});
      }
    }
  }, [_vm._v("新增")]), _c("el-table", {
    staticClass: "ml40",
    attrs: {
      data: _vm.orderCombine.orderCombineReports,
      "show-header": false,
      size: _vm.size_,
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "分类",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.goodsCat && scope.row.goodsCat.title,
            expression: "scope.row.goodsCat && scope.row.goodsCat.title"
          }],
          staticClass: "mr20"
        }, [_vm._v("分类：" + _vm._s(scope.row.goodsCat && scope.row.goodsCat.title))])];
      }
    }], null, false, 1816680634)
  }), _c("el-table-column", {
    attrs: {
      label: "数量",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.quantity,
            expression: "scope.row.quantity"
          }]
        }, [_vm._v("数量：" + _vm._s(scope.row.quantity))])];
      }
    }], null, false, 48876144)
  }), _c("el-table-column", {
    attrs: {
      label: "金额",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.amount,
            expression: "scope.row.amount"
          }]
        }, [_vm._v("金额：" + _vm._s(scope.row.amount))])];
      }
    }], null, false, 3476375156)
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editReport(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.delReport(scope.row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }], null, false, 4136746523)
  })], 1)] : _vm._e(), _vm.orderCombine.orderCombineAdditions && _vm.orderCombine.orderCombineAdditions.length > 0 ? [_c("p", {
    staticClass: "w100 p5 b mt10 border-f1f1f1-top"
  }, [_vm._v("增值服务")]), _c("p", {
    staticClass: "p5"
  }, _vm._l(_vm.orderCombine.orderCombineAdditions, function (orderCombineAddition) {
    return _c("span", {
      key: orderCombineAddition.id,
      staticClass: "ml40"
    }, [_vm._v(" " + _vm._s(orderCombineAddition.addition && orderCombineAddition.addition.title) + ":" + _vm._s(orderCombineAddition.comment) + " ")]);
  }), 0), _c("el-button", {
    staticClass: "ml40",
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.editAddition
    }
  }, [_vm._v("修改")])] : _vm._e()], 2), _c("el-collapse-item", {
    attrs: {
      title: "包裹",
      name: "orderPackages"
    }
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderPackages,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "third_order_no",
      label: "商城订单号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "日本国内快递号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "package_no",
      label: "包裹编码",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shelf_no",
      label: "货架号",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status_text",
      label: "状态",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "weight",
      label: "重量(kg)",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "size_type_text",
      label: "包裹大小",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "stored_at",
      label: "入库时间",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: _vm.orderCombine.status === 6 || _vm.orderCombine.status === 99 ? 80 : 0
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [(_vm.orderCombine.status === 6 || _vm.orderCombine.status === 99) && scope.row.status !== 4 ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.reStorePackage(scope.row);
            }
          }
        }, [_vm._v("重新入库")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "捆包",
      name: "orderCombinePackages"
    }
  }, [_vm.orderCombine.status !== 6 && _vm.orderCombine.status !== 99 ? _c("el-button", {
    attrs: {
      size: _vm.size_,
      type: "primary"
    },
    on: {
      click: _vm.addOrderCombinePackage
    }
  }, [_vm._v("新增捆包")]) : _vm._e(), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.orderCombinePackages,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "名称",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" 捆包" + _vm._s(scope.$index + 1) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "package_no",
      label: "包裹号",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.package_no,
            expression: "scope.row.package_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.package_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "tracking_no",
      label: "物流单号",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("p", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: scope.row.tracking_no,
            expression: "scope.row.tracking_no"
          }],
          staticClass: "omit cursor"
        }, [_vm._v(_vm._s(scope.row.tracking_no))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderCombineAddressText",
      label: "收件人"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "trans_amount",
      label: "运费",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "addition_amount",
      label: "增值服务",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "weight",
      label: "重量",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "size_text",
      label: "尺寸",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "v_w",
      label: "体积重",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "transFee.title",
      label: "运输渠道",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button-group", [(_vm.orderCombine.status === 6 || _vm.orderCombine.status === 99) && !scope.row.stored_at ? _c("el-button", {
          attrs: {
            type: "warning",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.reStore(scope.row);
            }
          }
        }, [_vm._v("捆包入库")]) : _vm._e(), _vm.orderCombine.status !== 6 && _vm.orderCombine.status !== 99 ? _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.editOrderCombinePackage(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.orderCombine.status !== 6 && _vm.orderCombine.status !== 99 ? _c("el-button", {
          attrs: {
            type: "danger",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return _vm.delOrderCombinePackage(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("el-collapse-item", {
    attrs: {
      title: "照片",
      name: "photos"
    }
  }, [_c("div", {
    staticClass: "w100"
  }, _vm._l(_vm.photos, function (photo, index) {
    return _c("el-image", {
      key: index,
      staticClass: "w-image-70 ml5",
      attrs: {
        src: photo.thumb,
        "preview-src-list": _vm.photos.map(i => i.thumb),
        fit: "contain"
      }
    });
  }), 1)]), _c("el-collapse-item", {
    attrs: {
      title: "支付记录",
      name: "orderPays"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: _vm.size_
    },
    on: {
      click: _vm.addPay
    }
  }, [_vm._v("新增")]), _c("PayTable", {
    ref: "payTable",
    attrs: {
      query: {
        order_combine_id: _vm.orderCombine.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderRefunds");
      }
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "退款记录",
      name: "orderRefunds"
    }
  }, [_c("RefundTable", {
    ref: "refundTable",
    attrs: {
      query: {
        order_combine_id: _vm.orderCombine.id,
        limit: 0
      }
    },
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderPays");
      }
    }
  })], 1), _c("el-collapse-item", {
    attrs: {
      title: "操作历史",
      name: "histories"
    }
  }, [_c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.histories,
      size: _vm.size_,
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "created_at",
      label: "日期",
      width: "140"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "admin_id",
      label: "操作人",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.admin_id === -1 ? "用户" : scope.row.admin_id === 0 ? "系统" : scope.row.admin.nickname) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "comment",
      label: "内容"
    }
  })], 1)], 1)], 1), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      beforeSubmit: _vm.beforeSubmit,
      labelWidth: _vm.labelWidth
    },
    on: {
      done: _vm.formDone
    },
    scopedSlots: _vm._u([{
      key: "packageIds",
      fn: function ({
        model
      }) {
        return [_c("w-table", {
          ref: "wPackagesSelectTable",
          attrs: {
            operates: {
              del: false,
              look: false,
              add: false,
              edit: false,
              multiDel: false
            },
            actions: {
              index: _vm.orderPackages,
              columns: [{
                label: "包裹编码",
                v: "package_no"
              }, {
                label: "重量",
                v: "weight"
              }, {
                label: "大小",
                v: "size_text"
              }]
            },
            pagination: false,
            asyncSummary: false,
            selectable: true,
            "max-height": "500"
          },
          on: {
            "selection-change": v => {
              model.package_ids = v.map(i => i.id);
            }
          }
        })];
      }
    }, {
      key: "print",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.printPackageNo
          }
        }, [_vm._v("打印入库标签")])];
      },
      proxy: true
    }])
  }), _c("w-dialog-form", {
    ref: "wAddressDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.formAddress,
      action: _vm.formActionAddress,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      labelWidth: "140px"
    },
    on: {
      done: _vm.getList
    }
  }), _c("OrderRefundAddDialog", {
    ref: "orderRefundAddDialog",
    on: {
      done: function ($event) {
        return _vm.loadDataList("orderRefunds");
      }
    }
  }), _c("PrintRecipt", {
    scopedSlots: _vm._u([{
      key: "printRecipt",
      fn: function () {
        return [_c("div", {
          staticStyle: {
            padding: "40px 20px 40px 20px"
          }
        }, [_c("p", [_vm._v(_vm._s(_vm.orderCombine.user_id) + "  " + _vm._s(_vm.getForm().model.weight) + "kg   " + _vm._s(_vm.dayjs().format("YYYY-MM-DD")))]), _c("img", {
          attrs: {
            src: _vm.$w_fun.getFullUrl("/index/index/barcode?code=" + _vm.getForm().model.package_no)
          }
        }), _c("p", [_vm._v(_vm._s(_vm.getForm().model.package_no))])])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };