var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.configs, function (config) {
    return _c("el-tab-pane", {
      key: config.group,
      attrs: {
        label: config.title,
        name: "" + config.group
      }
    }, [_c("el-container", {
      staticStyle: {
        height: "700px",
        border: "1px solid #eee"
      }
    }, [_c("el-aside", {
      staticStyle: {
        "border-right": "1px solid #eee"
      },
      attrs: {
        width: "200px",
        height: "100%"
      }
    }, [_c("el-menu", {
      on: {
        open: _vm.handleOpen
      }
    }, _vm._l(config.list, function (item) {
      return _c("el-menu-item", {
        key: item.name,
        ref: "menu" + item.name,
        refInFor: true,
        attrs: {
          index: item.name
        },
        on: {
          click: function ($event) {
            return _vm.changeMenu(item);
          }
        }
      }, [_c("i", {
        staticClass: "el-icon-menu"
      }), _c("span", {
        attrs: {
          slot: "title"
        },
        slot: "title"
      }, [_vm._v(_vm._s(item.title))])]);
    }), 1)], 1), _c("el-container", [_c("div", {
      staticClass: "w100"
    }, [_c("w-form", {
      ref: "wForm" + config.group,
      refInFor: true,
      staticClass: "mt25",
      attrs: {
        form: _vm.form,
        action: _vm.formAction,
        fullscreen: true,
        beforeSubmit: _vm.beforeSubmit,
        labelWidth: "160px"
      }
    })], 1), _c("div", {
      staticClass: "bottom-operation"
    }, [_c("el-button", {
      attrs: {
        loading: _vm.loading,
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.onSubmit();
        }
      }
    }, [_vm._v("提交")]), _c("el-button", {
      on: {
        click: function ($event) {
          return _vm.$router.go(-1);
        }
      }
    }, [_vm._v("返回")])], 1)])], 1)], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };