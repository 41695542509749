var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      "tree-props": {
        children: "children",
        hasChildren: "has_children"
      },
      lazy: true,
      load: _vm.onLoad,
      module: _vm.module,
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    }
  }), _c("AddressAddDialog", {
    ref: "addressAddDialog",
    on: {
      done: function ($event) {
        return _vm.getList(true);
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };