export default {
  name: 'KeywordReplaceAddDialog',
  data() {
    return {
      module: 'keywordReplace',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      keyword_cn,
      keyword_jp,
      status
    }) {
      this.form = {
        keyword_cn: {
          label: '中文',
          value: keyword_cn
        },
        keyword_jp: {
          label: '日语',
          value: keyword_jp
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};