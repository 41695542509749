import UserAddDialog from './dialog/userAddDialog';
import UserCouponDialog from './dialog/userCouponDialog';
import fa from "element-ui/src/locale/lang/fa";
export default {
  name: 'UserIndex',
  components: {
    UserAddDialog,
    UserCouponDialog
  },
  data() {
    return {
      module: 'user',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: true,
        multiDel: false
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.userAddDialog.open(row);
    },
    onAdd() {
      this.$refs.userAddDialog.open({});
    },
    onCoupon(row) {
      this.$refs.userCouponDialog.open(row);
    }
  }
};