import TransFeeCategoryAddDialog from '@/views/transFeeCategory/dialog/transFeeCategoryAddDialog';
export default {
  name: 'TransFeeAddDialog',
  components: {
    TransFeeCategoryAddDialog
  },
  data() {
    return {
      module: 'transFee',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  computed: {
    size_() {
      return this.$store.getters['size'];
    }
  },
  methods: {
    setForm({
      id,
      title,
      trans_fee_category_id,
      status,
      v_w_coefficient,
      v_w_formula_type = 0,
      max_weight,
      fee,
      fee_per_unit,
      transFeeItems = [],
      currency,
      is_need_report,
      is_id_card
    }) {
      this.form = {
        title: {
          label: '名称',
          value: title,
          width: '100%'
        },
        trans_fee_category_id: {
          label: '一级渠道',
          value: trans_fee_category_id,
          formType: 'select',
          options: '/transFeeCategory/select',
          attrs: {
            quickAdd: () => {
              this.$refs.transFeeCategoryAddDialog.open({});
            }
          }
        },
        max_weight: {
          label: '最大重量',
          value: max_weight,
          formType: 'number',
          required: false,
          attrs: {
            append: 'kg'
          }
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        v_w_coefficient: {
          label: '体积重系数',
          value: v_w_coefficient,
          formType: 'number',
          required: false
        },
        v_w_formula_type: {
          label: '计算公式',
          value: v_w_formula_type,
          formType: 'radio',
          options: '/transFee/formulaType',
          required: false
        },
        fee: {
          label: '手续费',
          value: fee,
          formType: 'number',
          attrs: {
            step: 0.01
          }
        },
        currency: {
          label: '货币类型',
          value: currency,
          formType: 'radio',
          options: '/siteConfig/currency'
        },
        is_need_report: {
          label: '关税申报',
          value: is_need_report,
          formType: 'boolean'
        },
        is_id_card: {
          label: '需要上传身份证',
          value: is_id_card,
          formType: 'boolean'
        },
        fee_per_unit: {
          label: '每0.1kg费用',
          value: fee_per_unit,
          formType: 'number',
          attrs: {
            step: 0.01
          },
          required: false,
          tooltip: '和阶梯费用配置互斥，只需要配置其中一个，阶梯费用配置优先级更高'
        },
        // is_need_backup_address: { label: '备用地址', value: is_need_backup_address, formType: 'boolean' },
        transFeeItems: {
          show: m => m.v_w_formula_type === 0,
          label: '阶梯费用配置',
          value: transFeeItems,
          formType: 'transFeeItems',
          width: '100%',
          required: false,
          tooltip: '和每0.1kg费用配置互斥，只需要配置其中一个，阶梯费用配置优先级更高'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};