import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-form", {
    ref: "wForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      fullscreen: true,
      itemWidth: "45%",
      labelWidth: "130px"
    },
    scopedSlots: _vm._u([{
      key: "arrayList",
      fn: function ({
        model
      }) {
        return [_c("el-table", {
          staticClass: "w100",
          attrs: {
            data: model.goods,
            size: _vm.size_,
            border: "",
            stripe: "",
            "max-height": "400"
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "title",
            label: "商品名称"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  maxlength: "80"
                },
                model: {
                  value: scope.row.title,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "title", $$v);
                  },
                  expression: "scope.row.title"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "link",
            label: "连接"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_
                },
                model: {
                  value: scope.row.link,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "link", $$v);
                  },
                  expression: "scope.row.link"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "price",
            label: "单价",
            width: "190"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  type: "number",
                  step: 1
                },
                scopedSlots: _vm._u([{
                  key: "append",
                  fn: function () {
                    return [_vm._v("円")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: scope.row.price,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "price", $$v);
                  },
                  expression: "scope.row.price"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "quantity",
            label: "数量",
            width: "110"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-input", {
                attrs: {
                  size: _vm.size_,
                  type: "number",
                  step: 1
                },
                model: {
                  value: scope.row.quantity,
                  callback: function ($$v) {
                    _vm.$set(scope.row, "quantity", $$v);
                  },
                  expression: "scope.row.quantity"
                }
              })];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            label: "操作",
            width: "60"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_c("el-button", {
                attrs: {
                  type: "danger",
                  size: _vm.size_,
                  icon: "el-icon-delete"
                },
                on: {
                  click: function ($event) {
                    return model.goods.splice(scope.$index, 1);
                  }
                }
              })];
            }
          }], null, true)
        })], 1), _c("div", {
          staticClass: "flex p5 w100"
        }, [_c("div", {
          staticClass: "h100"
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          },
          on: {
            click: function ($event) {
              return model.goods.push({
                link: "",
                title: "",
                price: undefined,
                quantity: undefined
              });
            }
          }
        }, [_vm._v("添加")])], 1), _c("el-upload", {
          ref: "upload",
          staticClass: "ml100",
          attrs: {
            action: _vm.$w_fun.getFullUrl("/admin/order/import"),
            multiple: false,
            "show-file-list": false,
            "on-success": _vm.formDone,
            headers: {
              authorization: _vm.token_
            },
            "list-type": "text",
            accept: ".xlsx"
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          }
        }, [_vm._v("导入")])], 1), _c("a", {
          staticClass: "blue cursor ml10 fr",
          attrs: {
            href: "https://api.paijianggo.com/cart.xlsx",
            target: "_blank"
          }
        }, [_vm._v("下载模板")])], 1)];
      }
    }])
  }), _c("div", {
    staticClass: "bottom-operation"
  }, [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v("提交")]), _c("el-button", {
    on: {
      click: function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };