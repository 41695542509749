export default {
  name: 'OrderPackageAddDialog',
  data() {
    return {
      module: 'orderPackage',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      site_id,
      order_id,
      order_no,
      order_combine_id,
      order_combine_package_id,
      user_id,
      handle_user_id,
      third_order_no,
      tracking_no,
      tracking_company,
      from_address,
      trans_amount,
      addition_id,
      addition_comment,
      amount,
      addition_amount,
      trans_pay_status,
      addition_pay_status,
      is_photo,
      package_no,
      shelf_no,
      purchase_at,
      send_at,
      stored_at,
      out_at,
      status,
      status_text,
      user_status,
      pay_status,
      size_type,
      weight,
      remark,
      goods_quantity,
      order_package_together_id,
      orderPackagePhotos,
      orderGoods
    }) {
      orderPackagePhotos = orderPackagePhotos || [];
      orderGoods = orderGoods || [];
      this.form = {
        // site_id: {
        //   label: '站点',
        //   value: site_id,
        //   formType: 'select',
        //   options: '/siteConfig/select'
        // },
        order_id: {
          show: false,
          value: order_id
        },
        // order_no: { label: '订单号', value: order_no },
        // order_combine_id: {
        //   label: '国际发货',
        //   value: order_combine_id,
        //   formType: 'select',
        //   options: '/orderCombine/select'
        // },
        // order_combine_package_id: {
        //   label: '国际发货包裹',
        //   value: order_combine_package_id,
        //   formType: 'select',
        //   options: '/orderCombinePackage/select'
        // },
        // user_id: {
        //   label: '',
        //   value: user_id,
        //   formType: 'select',
        //   options: '/user/select'
        // },
        // handle_user_id: {
        //   label: '处理人',
        //   value: handle_user_id,
        //   formType: 'select',
        //   options: '/handleUser/select'
        // },
        orderGoods: {
          label: '商品',
          value: orderGoods,
          formType: 'orderGoods',
          width: '100%'
        },
        order_no: {
          label: '订单号',
          value: order_no,
          formType: 'orderNo'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'radio',
          options: 'store/packageStatus',
          readonly: [2, 3, 4].indexOf(status) === -1,
          readonlyValue: status_text,
          attrs: {
            isButton: true
          }
        },
        third_order_no: {
          label: '平台订单号',
          value: third_order_no,
          required: false
        },
        tracking_no: {
          label: '快递单号',
          value: tracking_no,
          required: false
        },
        tracking_company: {
          label: '快递公司',
          value: tracking_company,
          required: false
        },
        from_address: {
          label: '发货地址',
          value: from_address,
          required: false
        },
        trans_amount: {
          label: '运费',
          value: trans_amount,
          formType: 'amount',
          required: false
        },
        amount: {
          label: '采购金额',
          value: amount,
          formType: 'amount',
          required: false
        },
        addition_amount: {
          label: '增值服务费',
          value: addition_amount,
          formType: 'amount',
          required: false
        },
        // trans_pay_status: { label: '运费支付状态', value: trans_pay_status, formType: 'number' },
        // addition_pay_status: { label: '增值服务费支付状态', value: addition_pay_status, formType: 'number' },
        // is_photo: { label: '是否拍照', value: is_photo, formType: 'boolean' },
        package_no: {
          label: '包裹号',
          value: package_no,
          required: false
        },
        shelf_no: {
          label: '货架号',
          value: shelf_no,
          required: false
        },
        purchase_at: {
          label: '采购时间',
          value: '',
          formType: 'datePicker',
          required: false,
          attrs: {
            type: 'datetime'
          }
        },
        // send_at: { label: '发货时间', value: send_at },
        // stored_at: { label: '入库时间', value: stored_at },
        // out_at: { label: '出库时间', value: out_at },

        // user_status: { label: '用户端状态', value: user_status, formType: 'number' },
        // pay_status: { label: '支付状态', value: pay_status, formType: 'number' },
        size_type: {
          value: size_type,
          label: '包裹大小',
          formType: 'radio',
          options: '/orderPackage/sizeType',
          attrs: {
            isButton: true
          },
          required: false
        },
        weight: {
          label: '包裹重量',
          value: weight,
          formType: 'number',
          attrs: {
            append: 'kg'
          },
          required: false
        },
        addition_id: {
          label: '验货拍照',
          value: addition_id,
          formType: 'select',
          options: '/addition/select',
          required: false
        },
        addition_comment: {
          label: '拍照备注',
          value: addition_comment,
          required: false
        },
        goods_quantity: {
          label: '商品数量',
          value: goods_quantity,
          formType: 'number',
          required: false
        },
        photos: {
          label: '照片',
          value: orderPackagePhotos.map(i => i.thumb),
          formType: 'upload',
          attrs: {
            multiple: true,
            limit: 20,
            accept: '.jpg,.png,.jpeg'
          },
          required: false,
          width: '100%'
        },
        remark: {
          label: '备注',
          value: remark,
          required: false,
          max: 500,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          },
          width: '100%'
        },
        delete_photos: {
          show: false,
          value: 1
        }
        // order_package_together_id: {
        //   label: '同捆包裹',
        //   value: order_package_together_id,
        //   formType: 'select',
        //   options: '/orderPackageTogether/select'
        // },
      };

      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};