import OrderAuctionsCartAddDialog from './dialog/orderAuctionsCartAddDialog';
export default {
  name: 'OrderAuctionsCartIndex',
  components: {
    OrderAuctionsCartAddDialog
  },
  data() {
    return {
      module: 'orderAuctionsCart',
      loading: false,
      operates: {
        del: false,
        look: false,
        add: true,
        edit: false,
        multiDel: true,
        other: [{
          title: '刷新',
          type: 'default',
          click: row => this.refresh(row)
        }, {
          title: '取消并退款',
          type: 'warning',
          show: row => [3, 4, 9].indexOf(row.status) > -1,
          click: row => this.cancel(row)
        }]
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.orderAuctionsCartAddDialog.open(row);
    },
    onAdd() {
      this.$refs.orderAuctionsCartAddDialog.open({});
    },
    refresh(row) {
      this.loading = true;
      this.$w_fun.post(`${this.module}/refresh`, {
        id: row.id
      }).then(res => {
        this.getList();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    async cancel(row) {
      this.loading = true;
      const confirm = await this.$confirm('确定取消吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`${this.module}/cancel`, {
        id: row.id
      }).then(res => {
        this.getList();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};