export default {
  name: 'TestAuctionsBid',
  data() {
    return {
      module: 'test',
      form: {
        third_goods_id: {
          label: '商品ID',
          value: ''
        },
        third_seller_id: {
          label: '卖家ID',
          value: ''
        },
        site_account_id: {
          label: '账号',
          value: undefined,
          formType: 'select',
          options: '/siteAccount/select?site_id_match=4'
        },
        my_top_price: {
          label: '出价',
          value: undefined,
          formType: 'number',
          attrs: {
            append: '円'
          }
        },
        quantity: {
          label: '数量',
          value: 1,
          formType: 'number'
        }
      },
      cartForm: {},
      cart: {
        id: undefined
      },
      goods: {},
      loading: false,
      refreshCount: 0
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        const result = await this.$refs.wForm.submit();
        this.loading = false;
        if (result && result.data && result.data.id) {
          this.cart.id = result.data.id;
          setTimeout(() => {
            this.refreshGoods();
          }, 5000);
        }
      } catch (e) {
        this.loading = false;
      }
    },
    setCartForm() {
      this.cartForm = {
        third_goods_id: {
          label: '商品ID',
          value: this.goods.third_goods_id
        },
        third_seller_id: {
          label: '卖家ID',
          value: this.goods.third_seller_id
        },
        title: {
          label: '商品标题',
          value: this.goods.title
        },
        price: {
          label: '当前价格',
          value: this.goods.price
        },
        next_price: {
          label: '下次出价',
          value: this.goods.next_price
        },
        final_price: {
          label: '一口价',
          value: this.goods.final_price
        },
        auction_status_text: {
          label: '一口价',
          value: this.goods.auction_status_text
        },
        status_text: {
          label: '状态',
          value: this.cart.status_text
        },
        end_time: {
          label: '结束时间',
          value: this.goods.end_time
        }
      };
    },
    refreshGoods() {
      this.refreshCount += 1;
      this.$w_fun.post(`${this.module}/getAuctions`, {
        third_goods_id: this.getForm().model.third_goods_id
      }).then(res => {
        this.cart = res.data.detail ? res.data.detail : {};
        this.goods = res.data.goods ? res.data.goods : {};
        const model = this.getForm().model;
        if (!model.third_seller_id && this.goods.third_seller_id) {
          model.third_seller_id = this.goods.third_seller_id;
        }
        this.setCartForm();
        if (!this.goods.id && this.refreshCount < 3) {
          setTimeout(() => {
            this.refreshGoods();
          }, 3000);
        } else {
          this.refreshCount = 0;
        }
      });
    },
    getForm() {
      return this.$refs.wForm;
    }
  }
};