import AdminSettingAddDialog from './dialog/adminSettingAddDialog';
export default {
  name: 'AdminSettingIndex',
  components: {
    AdminSettingAddDialog
  },
  data() {
    return {
      module: 'adminSetting',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      },

      tabs: ''
    };
  },
  created() {
    this.module = this.$route.path.split('/')[2];
  },
  methods: {
    changeTab(v) {
      this.tabs = v;
      // this.module = v
    },

    getList() {
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.adminSettingAddDialog.open(row);
    },
    onAdd() {
      this.$refs.adminSettingAddDialog.open({
        module: this.tabs
      });
    }
  }
};