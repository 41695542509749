import TransFeeItemAddDialog from './dialog/transFeeItemAddDialog';
export default {
  name: 'TransFeeItemIndex',
  components: {
    TransFeeItemAddDialog
  },
  data() {
    return {
      module: 'transFeeItem',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.transFeeItemAddDialog.open(row);
    },
    onAdd() {
      this.$refs.transFeeItemAddDialog.open({});
    }
  }
};