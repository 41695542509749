import AdditionAddDialog from './dialog/additionAddDialog';
export default {
  name: 'AdditionIndex',
  components: {
    AdditionAddDialog
  },
  data() {
    return {
      module: 'addition',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
      }
    };
  },
  methods: {
    getList() {
      this.$store.dispatch('cleanColumnOptions', this.module);
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.additionAddDialog.open(row);
    },
    onAdd() {
      this.$refs.additionAddDialog.open({});
    }
  }
};