export default {
  name: 'KeywordSensitiveAddDialog',
  data() {
    return {
      module: 'keywordSensitive',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      site_id,
      keyword_cn,
      keyword_jp,
      keyword_type,
      status
    }) {
      this.form = {
        // site_id: {
        //   label: '站点',
        //   value: site_id,
        //   formType: 'select',
        //   options: '/siteConfig/select'
        // },
        // keyword_cn: { label: '中文', value: keyword_cn },
        keyword_jp: {
          label: '日语',
          value: keyword_jp
        },
        keyword_type: {
          label: '关键词类型',
          value: keyword_type,
          formType: 'radio',
          options: {
            url: this.module + '/create',
            key: 'keywordType'
          }
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};