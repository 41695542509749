import "core-js/modules/es.array.push.js";
import OrderPackageAddDialog from './dialog/orderPackageAddDialog';
export default {
  name: 'OrderPackageIndex',
  components: {
    OrderPackageAddDialog
  },
  data() {
    return {
      module: 'orderPackage',
      operates: {
        del: true,
        look: false,
        add: false,
        edit: true,
        multiDel: false,
        other: [{
          title: '历史记录',
          click: (row, $index) => this.onLook(row),
          type: 'default'
        }, {
          title: '订单',
          click: (row, $index) => this.toOrder(row),
          type: 'default'
        }]
        // recycle: { autoLink: true },
      },

      dialogVisible: false,
      histories: []
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.orderPackageAddDialog.open(row);
    },
    onAdd() {},
    onLook(row) {
      this.histories = [];
      this.dialogVisible = true;
      this.$w_fun.post('orderPackage/histories', {
        id: row.id
      }).then(res => {
        this.histories = res.data.list;
      }).catch(() => {});
    },
    toOrder(row) {
      this.$router.push({
        name: 'OrderGet',
        query: {
          id: row.order_id
        }
      });
    }
  }
};