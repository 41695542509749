var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%",
      beforeSubmit: _vm.beforeSubmit
    },
    on: {
      done: v => _vm.$emit("done", v)
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };