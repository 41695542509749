var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      actions: {
        tabs: _vm.module + "/tabs"
      },
      selectable: true,
      "tab-key": "status_match",
      k: ""
    },
    on: {
      add: _vm.onAdd,
      look: _vm.onLook,
      changeTab: _vm.changeTab,
      "selection-change": _vm.selectChange
    },
    scopedSlots: _vm._u([{
      key: "multiOperate",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            disabled: !_vm.canMultipleAssign,
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.assign();
            }
          }
        }, [_vm._v("批量指派")]), _c("el-button", {
          attrs: {
            disabled: !_vm.canMultipleAccept,
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.accept();
            }
          }
        }, [_vm._v("批量接单")]), _c("el-button", {
          attrs: {
            disabled: !_vm.canMultipleDone,
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.done();
            }
          }
        }, [_vm._v("批量完成")])];
      },
      proxy: true
    }])
  }), _c("WorkAddDialog", {
    ref: "workAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("AssignDialog", {
    ref: "assignDialog",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };