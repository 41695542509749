var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      width: _vm.$w_fun.isMobile() ? "100%" : "60%"
    },
    on: {
      done: _vm.done
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };