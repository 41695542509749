var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: "权限管理",
      width: "600"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "permission-wrap"
  }, [_c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.allPermission,
      props: _vm.defaultProps,
      "default-checked-keys": _vm.permission,
      "node-key": "id",
      "show-checkbox": ""
    }
  })], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updatepermission
    }
  }, [_vm._v("确 定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };