var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("p", {
    staticClass: "p5 f16 b"
  }, [_vm._v("出价测试")]), _c("w-form", {
    ref: "wForm",
    attrs: {
      title: "出价",
      form: _vm.form,
      action: "test/auctionsBid",
      "item-width": "45%",
      labelWidth: "120px"
    }
  }), _c("div", {
    staticClass: "w100 p25"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("出价")]), _c("el-button", {
    on: {
      click: _vm.refreshGoods
    }
  }, [_vm._v("刷新商品")])], 1), _c("div", {
    staticClass: "w100 p5 mt30 border-f1f1f1-top"
  }, [_c("w-form", {
    ref: "wCartForm",
    attrs: {
      title: "商品",
      form: _vm.cartForm,
      readonly: true,
      timeout: 15000,
      "item-width": "45%",
      labelWidth: "120px"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };