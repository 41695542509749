var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      actions: {
        tabs: _vm.module + "/tabs"
      },
      selectable: true,
      "tab-key": "status_match",
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit,
      changeTab: _vm.changeTab
    },
    scopedSlots: _vm._u([{
      key: "multiOperate",
      fn: function () {
        var _vm$$refs$wTable, _vm$$refs$wTable$$ref, _vm$$refs$wTable2, _vm$$refs$wTable2$$re, _vm$$refs$wTable3, _vm$$refs$wTable3$$re, _vm$$refs$wTable4, _vm$$refs$wTable4$$re;
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.$refs.orderCombineImportDialog.open();
            }
          }
        }, [_vm._v("导入快递单号")]), _c("el-button", {
          attrs: {
            disabled: ((_vm$$refs$wTable = _vm.$refs.wTable) === null || _vm$$refs$wTable === void 0 ? void 0 : (_vm$$refs$wTable$$ref = _vm$$refs$wTable.$refs) === null || _vm$$refs$wTable$$ref === void 0 ? void 0 : _vm$$refs$wTable$$ref.table.selection.filter(i => i.status === 0).length) === 0,
            type: "primary"
          },
          on: {
            click: _vm.toHandle
          }
        }, [_vm._v("批量受理")]), _c("el-button", {
          attrs: {
            disabled: ((_vm$$refs$wTable2 = _vm.$refs.wTable) === null || _vm$$refs$wTable2 === void 0 ? void 0 : (_vm$$refs$wTable2$$re = _vm$$refs$wTable2.$refs) === null || _vm$$refs$wTable2$$re === void 0 ? void 0 : _vm$$refs$wTable2$$re.table.selection.length) === 0,
            type: "primary"
          },
          on: {
            click: _vm.exportDetail
          }
        }, [_vm._v("导出表格")]), _c("el-button", {
          attrs: {
            disabled: ((_vm$$refs$wTable3 = _vm.$refs.wTable) === null || _vm$$refs$wTable3 === void 0 ? void 0 : (_vm$$refs$wTable3$$re = _vm$$refs$wTable3.$refs) === null || _vm$$refs$wTable3$$re === void 0 ? void 0 : _vm$$refs$wTable3$$re.table.selection.length) === 0,
            type: "primary"
          },
          on: {
            click: _vm.exportIdCard
          }
        }, [_vm._v("导出身份证")]), _c("el-button", {
          attrs: {
            disabled: ((_vm$$refs$wTable4 = _vm.$refs.wTable) === null || _vm$$refs$wTable4 === void 0 ? void 0 : (_vm$$refs$wTable4$$re = _vm$$refs$wTable4.$refs) === null || _vm$$refs$wTable4$$re === void 0 ? void 0 : _vm$$refs$wTable4$$re.table.selection.length) === 0,
            type: "primary"
          },
          on: {
            click: _vm.printList
          }
        }, [_vm._v("打印转运单")])];
      },
      proxy: true
    }])
  }), _c("OrderCombineAddDialog", {
    ref: "orderCombineAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("WorkAddDialog", {
    ref: "workAddDialog",
    on: {
      done: _vm.getList
    }
  }), _c("OrderCombineImportDialog", {
    ref: "orderCombineImportDialog",
    on: {
      done: _vm.getList
    }
  }), _vm.printItem.order_no ? _c("Print", {
    ref: "print",
    scopedSlots: _vm._u([{
      key: "print",
      fn: function () {
        return _vm._l(_vm.printTableList, function (item, i) {
          return _c("div", {
            key: i,
            staticClass: "p50",
            staticStyle: {
              "page-break-after": "always",
              padding: "40px 20px"
            }
          }, [_c("img", {
            attrs: {
              src: _vm.$w_fun.getFullUrl("/index/index/barcode?code=" + item.id)
            }
          }), _c("div", {
            staticClass: "print-table"
          }, [_c("div", [_c("span", [_vm._v("订单号：")]), _c("span", [_vm._v(_vm._s(item.order_no))])]), _c("div", [_c("span", [_vm._v("用户码：")]), _c("span", [_vm._v(_vm._s(item.user_id))])]), _c("div", [_c("span", [_vm._v("用户留言：")]), _c("span", [_vm._v(_vm._s(item.comment))])]), _c("div", [_c("span", [_vm._v("管理员备注：")]), _c("span", [_vm._v(_vm._s(item.remark))])]), _c("div", [_c("span", [_vm._v("运输渠道：")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.transFee.title))])]), _c("div", [_c("span", [_vm._v("增值服务：")]), _vm._l(item.orderCombineAdditions, function (Jtem, k) {
            return _c("div", {
              key: k
            }, [_c("span", [_vm._v(_vm._s(Jtem.addition.title + (Jtem.comment ? "：" + Jtem.comment : "")))])]);
          })], 2), _c("div", [_c("p", [_vm._v("包裹：")]), _c("table", [_c("tr", [_c("td", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_vm._v("包裹号")]), _c("td", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_vm._v("货架号")]), _c("td", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_vm._v("重量")]), _c("td", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_vm._v("备注")])]), _vm._l(item.orderPackages, function (ktem, j) {
            return _c("tr", {
              key: j
            }, [_c("td", {
              staticStyle: {
                "text-align": "center"
              }
            }, [_vm._v(_vm._s(ktem.package_no))]), _c("td", {
              staticStyle: {
                "text-align": "center"
              }
            }, [_vm._v(_vm._s(ktem.shelf_no))]), _c("td", {
              staticStyle: {
                "text-align": "center"
              }
            }, [_vm._v(_vm._s(ktem.weight))]), _c("td", {
              staticStyle: {
                "text-align": "center"
              }
            }, [_vm._v(_vm._s(ktem.remark))])]);
          })], 2)])])]);
        });
      },
      proxy: true
    }], null, false, 3826435206)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };