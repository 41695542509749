export default {
  name: 'OrderRefundTable',
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      module: 'orderRefund',
      operates: {
        del: false,
        look: false,
        add: true,
        edit: false,
        multiDel: false,
        other: [{
          title: '审核',
          click: row => this.toVerify(row),
          show: row => row.status !== 1 && row.status !== 2
        }, {
          title: '打款',
          type: 'warning',
          click: row => this.toRefund(row),
          show: row => row.status === 3
        }]
        // recycle: { autoLink: true },
      },

      actions: {
        columns: [{
          label: '创建时间',
          v: 'created_at'
        }, {
          label: '退款时间',
          v: 'refund_at'
        }, {
          label: '金额',
          v: 'amount'
        }, {
          label: '状态',
          v: 'status_text'
        }, {
          label: '退款方式',
          v: 'payment_type_text'
        }, {
          label: '退款类型',
          v: 'refund_aim_type_text'
        }, {
          label: '退款内容',
          v: 'reason'
        }, {
          label: '备注',
          v: 'comment'
        }, {
          label: '流水号',
          v: 'trade_no'
        }, {
          label: '申请人',
          v: 'admin.nickname'
        }, {
          label: '审核人',
          v: 'verifyAdmin.nickname'
        }, {
          label: '打款人',
          v: 'payAdmin.nickname'
        }]
      },
      form: {},
      formAction: ''
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.onRefresh();
    },
    formDone() {
      this.getList();
      this.$emit('done');
    },
    toVerify(row) {
      this.form = {
        id: {
          show: false,
          value: row.id
        },
        status: {
          label: '状态',
          value: undefined,
          required: true,
          formType: 'radio',
          options: '/orderRefund/verifyStatus'
        },
        reason: {
          label: '原因',
          value: '',
          required: false
        }
      };
      this.formAction = 'orderRefund/verify';
      this.$refs.wDialogForm.visible = true;
    },
    async toRefund(row) {
      const confirm = await this.$confirm('该操作不可逆，确定要退款吗？', '提示', {
        type: 'warning'
      }).catch(() => {});
      if (!confirm) {
        return;
      }
      this.$w_fun.post(`orderRefund/pay`, {
        id: row.id
      }).then(res => {
        this.$emit('done');
      });
    }
  }
};