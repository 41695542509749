var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module,
      actions: {
        tabs: _vm.module + "/tabs"
      },
      "tab-key": "module_match",
      k: ""
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit,
      changeTab: _vm.changeTab
    }
  }), _c("ArticleCategoryAddDialog", {
    ref: "articleCategoryAddDialog",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };