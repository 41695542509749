export default {
  data() {
    return {
      show: true,
      target: undefined,
      options: [],
      form: {}
    };
  },
  methods: {
    setForm(ids) {
      this.form = {
        id: {
          show: false,
          value: ids
        },
        handle_type: {
          label: '类型',
          value: undefined,
          formType: 'radio',
          options: [{
            label: '指定人',
            value: 1
          }, {
            label: '指定部门',
            value: 2
          }]
        },
        handle_admin_department_id: {
          label: '部门',
          value: undefined,
          formType: 'treeRadioFree',
          options: '/adminDepartment/selectTree',
          show: m => m.handle_type === 2
        },
        handle_admin_id: {
          label: '人员',
          value: undefined,
          formType: 'select',
          options: '/admin/select',
          show: m => m.handle_type === 1
        }
      };
    },
    open(row) {
      this.setForm(row);
      this.$refs.dialogForm.visible = true;
    }
  }
};