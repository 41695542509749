import OrderPayAddDialog from './dialog/orderPayAddDialog';
import { isPro } from '@/utils/w';
export default {
  name: 'OrderPayIndex',
  components: {
    OrderPayAddDialog
  },
  data() {
    return {
      module: 'orderPay',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        multiDel: false,
        // recycle: { autoLink: true },
        other: [{
          title: '支付成功',
          type: 'warning',
          show: row => row.status === 0 && !isPro(),
          click: row => this.paySuccess(row)
        }]
      }
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.orderPayAddDialog.open(row);
    },
    onAdd() {
      this.$refs.orderPayAddDialog.open({});
    },
    paySuccess(row) {
      this.$w_fun.post(`${this.module}/paySuccess`, {
        id: row.id
      }).then(() => {
        this.getList();
      });
    }
  }
};