import UserWithdrawAddDialog from './dialog/userWithdrawAddDialog';
export default {
  name: 'UserWithdrawIndex',
  components: {
    UserWithdrawAddDialog
  },
  data() {
    return {
      module: 'userWithdraw',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$store.dispatch('cleanColumnOptions', this.module);
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.userWithdrawAddDialog.open(row);
    },
    onAdd() {
      this.$refs.userWithdrawAddDialog.open({});
    }
  }
};