var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "printRecipt",
    staticClass: "printRecipt",
    attrs: {
      id: "printRecipt"
    }
  }, [_vm._t("printRecipt")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };