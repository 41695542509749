export default {
  name: 'OrderCommentTemplateAddDialog',
  data() {
    return {
      module: 'orderCommentTemplate',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      title,
      comment,
      status = 1
    }) {
      this.form = {
        title: {
          label: '模板名称',
          value: title
        },
        comment: {
          label: '评论内容',
          value: comment
        },
        status: {
          label: '是否启用',
          value: status,
          formType: 'status'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
      this.$store.dispatch('cleanColumnOptions', this.module + '/select');
    }
  }
};