export default {
  name: 'GoodsAddDialog',
  data() {
    return {
      module: 'goods',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      third_goods_id,
      site_id,
      third_seller_id,
      title,
      thumb,
      price,
      stock,
      status,
      is_succeess,
      third_updated_at,
      final_price,
      start_time,
      end_time,
      freight_status,
      cate_id,
      renew_num,
      next_updated_at
    }) {
      this.form = {
        third_goods_id: {
          label: '第三方的id',
          value: third_goods_id,
          formType: 'select',
          options: '/thirdGoods/select'
        },
        site_id: {
          label: '',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select'
        },
        third_seller_id: {
          label: '卖家ID',
          value: third_seller_id,
          formType: 'select',
          options: '/seller/select'
        },
        title: {
          label: '名称',
          value: title
        },
        thumb: {
          label: '图片',
          value: thumb
        },
        price: {
          label: '单价',
          value: price,
          formType: 'number'
        },
        stock: {
          label: '库存',
          value: stock,
          formType: 'number'
        },
        status: {
          label: '商品状态',
          value: status,
          formType: 'status'
        },
        is_succeess: {
          label: '商品是否全部数据拉取完成',
          value: is_succeess,
          formType: 'boolean'
        },
        third_updated_at: {
          label: '',
          value: third_updated_at
        },
        final_price: {
          label: '立即购买价格',
          value: final_price,
          formType: 'number'
        },
        start_time: {
          label: '上线时间',
          value: start_time
        },
        end_time: {
          label: '下线时间',
          value: end_time
        },
        freight_status: {
          label: '运费状态，1=包邮，2=货到付款，3=代扣，4=买家负担，0=未知',
          value: freight_status,
          formType: 'number'
        },
        cate_id: {
          label: '分类id',
          value: cate_id,
          formType: 'select',
          options: '/cate/select'
        },
        renew_num: {
          label: '更新次数',
          value: renew_num,
          formType: 'number'
        },
        next_updated_at: {
          label: '',
          value: next_updated_at
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};