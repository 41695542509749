import "core-js/modules/es.array.push.js";
export default {
  name: 'OrderPackageIndex',
  data() {
    return {
      module: 'orderPackage',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: true,
        multiDel: false
        // recycle: { autoLink: true },
      }
    };
  },

  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      if (!row.order_combine_id) {
        this.$router.push({
          name: 'OrderGet',
          query: {
            id: row.order_id
          }
        });
      } else {
        this.$router.push({
          name: 'OrderCombineGet',
          query: {
            id: row.order_combine_id
          }
        });
      }
    },
    onAdd() {}
  }
};