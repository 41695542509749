import AdminSettingAddDialog from '@/views/adminSetting/dialog/adminSettingAddDialog';
export default {
  name: 'UserBargainAddDialog',
  components: {
    AdminSettingAddDialog
  },
  data() {
    return {
      module: 'userBargain',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      user_id,
      site_id,
      site_account_id,
      order_pay_id,
      third_goods_id,
      shop_id,
      shop_title,
      status,
      content,
      reply_at,
      reason
    }) {
      this.form = {
        site_account_id: {
          label: '账号',
          value: site_account_id,
          formType: 'select',
          options: '/siteAccount/select?site_id_match=' + site_id
        },
        status: {
          label: '状态',
          value: status,
          formType: 'select',
          options: {
            url: '/userBargain/create',
            key: 'statusMap'
          }
        },
        content: {
          label: '留言内容',
          value: content,
          required: false,
          formType: 'textarea',
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        },
        reason: {
          label: '审核失败原因',
          value: reason,
          show: m => m.status === 5,
          formType: 'select',
          options: 'adminSetting/valueSelect?module_match=userBargainReason',
          attrs: {
            quickAdd: () => {
              this.$refs.adminSettingAddDialog.open({
                module: 'userBargainReason'
              });
            }
          }
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};