export default {
  name: 'SiteConfigAddDialog',
  data() {
    return {
      module: 'siteConfig',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      site_id,
      mini_logo,
      index_thumb,
      site_list,
      logo,
      is_online,
      status,
      sort,
      auto_cancel
    }) {
      this.form = {
        mini_logo: {
          label: '小logo',
          value: [{
            path: mini_logo,
            url: mini_logo
          }],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            tip: '建议尺寸：24px * 24px'
          },
          type: 'array'
        },
        index_thumb: {
          label: '首页logo',
          value: [{
            path: index_thumb,
            url: index_thumb
          }],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            tip: [1, 2].includes(site_id) ? '建议尺寸：200px * 100px' : '建议尺寸：80px * 80px'
          },
          type: 'array'
        },
        site_list: {
          label: '站点商城logo',
          value: [{
            path: site_list,
            url: site_list
          }],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            tip: '建议尺寸：240px * 180px，图片主要内容尽量放在中间的180*180'
          },
          type: 'array'
        },
        logo: {
          label: '站点首页logo',
          value: [{
            path: logo,
            url: logo
          }],
          formType: 'upload',
          attrs: {
            accept: '.jpg,.png,.jpeg',
            tip: '建议尺寸：120px * 40px'
          },
          type: 'array',
          required: false
        },
        is_online: {
          label: '是否上线',
          value: is_online,
          formType: 'boolean'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        },
        sort: {
          label: '排序',
          value: sort,
          formType: 'number',
          required: false
        },
        auto_cancel: {
          label: '订单过期时间',
          value: auto_cancel,
          formType: 'number',
          attrs: {
            append: '分钟'
          }
        }
      };
      if (site_id) {
        this.form.site_id = {
          show: false,
          value: site_id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};