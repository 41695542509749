export default {
  name: "Print",
  components: {},
  props: {
    printData: {
      type: String,
      default: null
    }
  },
  methods: {
    /**
     * 打印
     */
    // handlePrint2(){
    //    // 创建新的window对象
    //     const iframeEL= document.querySelector('.target-el-iframe');
    //     if(iframeEL){
    //       iframeEL.remove();
    //     }
    //     //目标元素
    //     const info=document.querySelector('.printcontents');
    //     //创建iframe
    //     const iframeEl= document.createElement('iframe');
    //     iframeEl.classList.add('target-el-iframe')
    //     iframeEl.setAttribute('style','width:90%')

    //     //添加到页面
    //     document.body.appendChild(iframeEl)
    //     const doc = iframeEl.contentWindow.document
    //     doc.write('<div class="print-iframe">' + info.innerHTML + '</div>')
    //     doc.write('<style>@page{size:auto;margin: 0.5cm 1cm 0cm 1cm;}</style>')
    //     doc.close()
    //     //引入第三方样式文件
    //     // const link = doc.createElement('link');
    //     // link.rel = 'stylesheet';
    //     // link.href = '/static/print/print.less';

    //     // doc.head.appendChild(link);
    //     //打印
    //     iframeEl.onload = () => {
    //       iframeEl.contentWindow.print()
    //     }
    // },
    // handlePrint() {
    //   const printWindow = window.open('', '_blank');
    //   const printContent = document.getElementById('printcontents').innerHTML;
    //   printWindow.document.write(printContent);
    //   printWindow.document.close();
    //   printWindow.focus();
    //   printWindow.print();
    //   setTimeout(() => {
    //     printWindow.close();
    //   }, 5000)
    // },
    handlePrint() {
      var iframe = document.getElementById("print-iframe");
      // if (!iframe) {
      var el = document.getElementById("printcontents");
      iframe = document.createElement("IFRAME");
      var doc = null;
      iframe.setAttribute("id", "print-iframe");
      iframe.setAttribute("style", "position:absolute;width:0px;height:0px;left:-500px;top:-500px;");
      document.body.appendChild(iframe);
      doc = iframe.contentWindow.document;
      //自定义样式
      doc.write('<style media="print">@page {size: auto;margin: 0mm;}</style>');
      // 添加表格样式(解决边框丢失问题)
      doc.write('<style media="print">table {border-collapse: collapse; width: 100%;} th, td {border: 1px solid #dfe6ec; padding: 8px 0;}</style>');
      //解决出现页眉页脚和路径的问题
      doc.write("<div>" + el.innerHTML + "</div>");
      doc.close();
      iframe.contentWindow.focus();
      // }
      setTimeout(function () {
        iframe.contentWindow.print();
      }, 50); //解决第一次样式不生效的问题
      if (navigator.userAgent.indexOf("MSIE") > 0) {
        document.body.removeChild(iframe);
      }
    }
  }
};