var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog", {
    attrs: {
      width: "800px",
      title: "导入"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("div", {
          staticClass: "flex justify-content-center"
        }, [_c("div", {
          staticClass: "h100"
        }, [_c("a", {
          staticClass: "cursor blue p5",
          attrs: {
            href: "https://api.paijianggo.com/import.xlsx"
          }
        }, [_vm._v("下载模板")])]), _c("el-upload", {
          ref: "upload",
          staticClass: "ml20",
          attrs: {
            action: _vm.$w_fun.getFullUrl("/admin/orderCombine/import"),
            multiple: false,
            "show-file-list": false,
            "on-success": _vm.formDone,
            headers: {
              authorization: _vm.token_
            },
            "list-type": "text",
            accept: ".xlsx"
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.size_
          }
        }, [_vm._v("选择文件")])], 1), _c("div", {
          staticClass: "h100 ml20"
        }, [_c("el-button", {
          attrs: {
            size: _vm.$store.getters["size"]
          },
          on: {
            click: _vm.done
          }
        }, [_vm._v("关闭")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "w100 p10"
  }, [_c("p", {
    staticClass: "b"
  }, [_vm._v("导入结果：")]), _c("el-collapse", {
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.list, function (field) {
    return _vm.result && _vm.result[field.name] && _vm.result[field.name].length > 0 ? _c("el-collapse-item", {
      key: field.name,
      attrs: {
        name: field.name
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c("span", {
            class: field.color
          }, [_vm._v(_vm._s(field.title) + _vm._s(_vm.result[field.name].length) + "条" + _vm._s(field.tip))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(_vm.result[field.name], function (item) {
      return _c("p", {
        staticClass: "p5 ml20"
      }, [_vm._v(" 订单号："), _c("span", {
        directives: [{
          name: "copy",
          rawName: "v-copy",
          value: item.order_no,
          expression: "item.order_no"
        }],
        staticClass: "cursor blue"
      }, [_vm._v(_vm._s(item.order_no))]), _vm._v("， 包裹号："), _c("span", {
        directives: [{
          name: "copy",
          rawName: "v-copy",
          value: item.package_no,
          expression: "item.package_no"
        }],
        staticClass: "cursor blue"
      }, [_vm._v(_vm._s(item.package_no))]), _vm._v("， 快递单号："), _c("span", {
        directives: [{
          name: "copy",
          rawName: "v-copy",
          value: item.tracking_no,
          expression: "item.tracking_no"
        }],
        staticClass: "cursor blue"
      }, [_vm._v(_vm._s(item.tracking_no))])]);
    }), 0) : _vm._e();
  }), 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };