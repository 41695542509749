import { getLodop } from "./LodopFuncs";
// import { hiprint } from "vue-plugin-hiprint";
export default {
  name: "PrintRecipt",
  data() {
    return {
      vendorId: " ",
      //打印机的 vendorId
      device: null // 设备
    };
  },

  methods: {
    /**
     * 打印小票 c-lodop
     */
    printReceipt(userId, model) {
      // console.log(model)
      var myDate = new Date();
      // var dubid=ubid?ubid.substr(-1):'';
      var dubid = "";
      var rexpid = model.package_no;
      var rkg = model.weight;
      const printContent = document.querySelector(".printRecipt").innerHTML;
      // const print_style = document.querySelector('.printRecipt').innerHTML
      const print_style = "";
      const style_innerHTML = `<style>${print_style}</style>`;
      const printHtml = `${style_innerHTML}<body>${printContent}</body>`;
      const LODOP = getLodop();
      // console.log(LODOP);
      if (!LODOP) {
        this.$message.error("未安装Web打印服务C-Lodop，请安装后再试");
        return;
      }
      LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true); //设置打印模式 //
      /*LODOP.SET_LICENSES("","6D9E9FBA2ADB565938C033845BF5A9EF","C94CEE276DB2187AE6B65D56B3FC2848","4154EF517CB1509D9291CA6610B5CF0B");*/
      LODOP.SET_LICENSES("", "39AF053E4C000375C1DBE7C83E94CDDB", "C94CEE276DB2187AE6B65D56B3FC2848", ""); //设置软件产品注册信息
      LODOP.PRINT_INIT("入库打印"); //打印初始化
      // LODOP.SET_PREVIEW_WINDOW(1,0,1,900,500,"");//设置预览窗口
      LODOP.SET_PRINT_PAGESIZE(1, "70mm", "40mm"); //设定纸张大小1,"70mm","40mm"
      LODOP.SET_PRINT_STYLE("FontSize", 13); //设置打印风格
      LODOP.SET_PRINT_STYLE("Bold", 1); //
      LODOP.ADD_PRINT_TEXT(1, 5, 200, 50, rexpid);
      ; //增加存文本打印象
      LODOP.ADD_PRINT_HTML(0, 0, "100%", "100%", printHtml); //增加超文本打印项
      // LODOP.ADD_PRINT_TEXT(12, 15, 150, 20, userId);
      // LODOP.SET_PRINT_STYLEA(4, "FontSize", 10); //设置上面这个条码下方的文字字体大小
      // LODOP.ADD_PRINT_TEXT(12, 150, 210, 20, rkg + " kg");
      // LODOP.SET_PRINT_STYLEA(4, "FontSize", 10);
      // LODOP.ADD_PRINT_BARCODE(40, 15, 200, 45, "128A", rexpid);
      // LODOP.SET_PRINT_STYLEA(3, "Bold", 1);
      // LODOP.SET_PRINT_STYLEA(3, "FontSize", 8); //设置上面这个条码下方的文字字体大小
      // LODOP.ADD_PRINT_TEXT(100, 15, 210, 20, myDate.toLocaleString("zh-cn")); //.toLocaleDateString() myDate.getFullYear()+'-'+myDate.getMonth()+'-'+myDate.getDate()
      // LODOP.SET_PRINT_STYLEA(4, "FontSize", 10);

      //     LODOP.ADD_PRINT_TEXT(2,125,550,50,ubid);
      // LODOP.SET_PRINT_STYLEA(6,"FontSize",20);
      //LODOP.SET_PRINT_STYLEA(4,"Bold",1);//(增强型) 设置打印项风格A,继承SET_PRINT_STYLE的所有属性
      //LODOP.SET_SHOW_MODE("LANGUAGE",0);//2018.06.19
      //LODOP.SET_PRINT_STYLEA(3,"FontSize",80);

      //LODOP.SET_PRINT_STYLEA(3,"FontSize",20);//(增强型) 设置打印项风格A,继承SET_PRINT_STYLE的所有属性
      //LODOP.ADD_PRINT_TEXT(110,18,170,30,depotinfo);

      LODOP.SET_PRINTER_INDEX("Gprinter  GP-9025T"); //指定打印设备
      // LODOP.PREVIEW();//预览
      LODOP.PRINT(); //打印
    }
    /**
     * 打印小票 hiprint
     */
    // async printReceipt1() {
    //   hiprint.init({
    //     host: "http://192.168.101.118:17521", // 打印客户端的地址
    //     // host: "http://192.168.31.217:17521", // 打印客户端的地址
    //     token: "vue-plugin-hiprint", // 与打印客户端相同的 token
    //   });
    //   //初始化
    //   const hiprintTemplate = new hiprint.PrintTemplate();
    //   hiprintTemplate.printByHtml2(this.$refs.printRecipt);
    // },
  }
};