export default {
  methods: {
    bcadd(a, b, f = 2) {
      return parseFloat(((Math.round(a * Math.pow(10, f)) + Math.round(b * Math.pow(10, f))) / Math.pow(10, f)).toFixed(f));
    },
    bcsub(a, b, f = 2) {
      return parseFloat(((Math.round(a * Math.pow(10, f)) - Math.round(b * Math.pow(10, f))) / Math.pow(10, f)).toFixed(f));
    },
    bcdiv(a, b, f = 2) {
      return parseFloat((Math.round(Math.round(a * Math.pow(10, f)) / Math.round(b * Math.pow(10, f))) / Math.pow(10, f)).toFixed(f));
    },
    bcmul(a, b, f = 2) {
      return parseFloat((Math.round(Math.round(a * Math.pow(10, f)) * Math.round(b * Math.pow(10, f))) / Math.pow(10, f)).toFixed(f));
    }
  }
};