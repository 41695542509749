import SiteAccountAddDialog from './dialog/siteAccountAddDialog';
import BindDialog from './dialog/bindDialog.vue';
export default {
  name: 'SiteAccountIndex',
  components: {
    SiteAccountAddDialog,
    BindDialog
  },
  data() {
    return {
      module: 'siteAccount',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        multiDel: true,
        // recycle: { autoLink: true },
        other: [
        // {
        //   show: v => v.site_id === 4,
        //   title: '重新登录',
        //   click: row => this.login(row)
        // },
        {
          show: v => v.site_id === 3,
          title: '账号授权',
          click: row => this.empower(row)
        }, {
          show: v => v.site_id === 4,
          title: '绑定用户',
          click: row => this.bindWeChat(row)
        }]
      }
    };
  },
  methods: {
    getList() {
      this.$refs.wTable.getList();
    },
    onEdit(row) {
      this.$refs.siteAccountAddDialog.open(row);
    },
    onAdd() {
      this.$refs.siteAccountAddDialog.open({});
    },
    // 煤炉需要单独授权
    empower(row) {
      this.$w_fun.post('/siteAccount/empower', {
        id: row.id
      }).then(response => {
        window.open(response.data.empower_url, '_blank');
      });
    },
    login(row) {
      this.$w_fun.post('/siteAccount/login', {
        id: row.id
      });
    },
    bindWeChat(row) {
      this.$refs.bindDialog.open(row);
    }
  }
};