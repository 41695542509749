export default {
  name: 'OrderAuctionsCartAddDialog',
  data() {
    return {
      module: 'orderAuctionsCart',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      third_goods_id,
      site_account_id,
      order_pay_id,
      my_top_price,
      trans_amount,
      exchange_rate,
      amount,
      quantity,
      user_id,
      status,
      auctions_type,
      trans_prefer,
      addition_id,
      addition_comment,
      comment,
      reason
    }) {
      this.form = {
        third_goods_id: {
          label: '商品ID',
          value: third_goods_id,
          formType: 'select',
          options: '/thirdGoods/select'
        },
        site_account_id: {
          label: '账号',
          value: site_account_id,
          formType: 'select',
          options: '/siteAccount/select'
        },
        my_top_price: {
          label: '我的出价',
          value: my_top_price,
          formType: 'number'
        },
        trans_amount: {
          label: '运费',
          value: trans_amount,
          formType: 'number'
        },
        exchange_rate: {
          label: '汇率',
          value: exchange_rate,
          formType: 'number'
        },
        amount: {
          label: '授权金额',
          value: amount,
          formType: 'number'
        },
        quantity: {
          label: '数量',
          value: quantity,
          formType: 'number'
        },
        user_id: {
          label: '',
          value: user_id,
          formType: 'remote',
          options: '/user/select'
        },
        auctions_type: {
          label: '出价时机',
          value: auctions_type,
          formType: 'select',
          options: {
            url: '/orderAuctionsCart/create',
            key: 'auctionsType'
          }
        },
        trans_prefer: {
          label: '运输方式偏好',
          value: trans_prefer
        },
        addition_id: {
          value: addition_id,
          label: '验货拍照',
          formType: 'select',
          options: '/addition/select?position_type_byte=2'
        },
        addition_comment: {
          label: '验货拍照备注',
          value: addition_comment
        },
        comment: {
          label: '用户留言',
          value: comment
        },
        reason: {
          label: '失败原因',
          value: reason
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    done(v) {
      this.$emit('done', v);
    }
  }
};