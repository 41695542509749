export default {
  name: 'UserSearchHistoryAddDialog',
  data() {
    return {
      module: 'userSearchHistory',
      formAction: '',
      form: {},
      readonly: false
    };
  },
  methods: {
    setForm({
      id,
      keyword,
      user_id
    }) {
      this.form = {
        keyword: {
          label: '关键词',
          value: keyword
        },
        user_id: {
          label: '用户',
          value: user_id,
          formType: 'remote',
          options: '/user/select'
        }
      };
      if (id) {
        this.form.id = {
          show: false,
          value: id
        };
        this.formAction = `${this.module}/edit`;
      } else {
        this.formAction = `${this.module}/add`;
      }
      this.$refs.wDialogForm.visible = true;
    },
    open(data, readonly = false) {
      this.setForm(data);
      this.readonly = readonly;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};