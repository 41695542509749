import "core-js/modules/es.array.push.js";
import { getToken } from '@/utils/auth';
export default {
  title: 'OrderAdd',
  computed: {
    size_() {
      return this.$store.getters['size'];
    },
    token_() {
      return getToken();
    }
  },
  data() {
    return {
      module: 'order',
      model: {
        id: undefined
      },
      formAction: '',
      form: {},
      loading: false,
      site_id: 0
    };
  },
  created() {
    this.setForm({
      site_id: this.$route.query.site_id_match
    });
  },
  methods: {
    setForm({
      site_id
    }) {
      this.form = {
        is_manual_purchase: {
          label: '人工采购',
          value: undefined,
          formType: 'boolean'
        },
        user_id: {
          label: '会员',
          value: undefined,
          formType: 'remote',
          options: '/user/select'
        },
        site_id: {
          label: '站点',
          value: site_id,
          formType: 'select',
          options: '/siteConfig/select',
          methods: {
            change: v => {
              if (v > 3) {
                this.$message.error('暂不支持');
                this.getForm().model.site_id = this.site_id;
                return;
              }
              this.site_id = v;
              this.getForm().model.goods = [];
            }
          }
        },
        pay_image: {
          show: model => model.site_id === 1,
          label: '付款码图片',
          value: [],
          formType: 'upload',
          attrs: {
            multiple: true,
            limit: 5,
            accept: '.jpg,.png,.jpeg'
          }
        },
        goods_image: {
          show: model => model.site_id === 1,
          label: '商品图片',
          value: [],
          formType: 'upload',
          attrs: {
            multiple: true,
            limit: 5,
            accept: '.jpg,.png,.jpeg'
          }
        },
        instead_pay_type_id: {
          show: model => model.site_id === 1,
          label: '支付方式',
          value: undefined,
          formType: 'select',
          options: '/insteadPayType/select'
        },
        goods: {
          show: model => model.site_id === 2,
          label: '商品',
          value: [],
          formType: 'arrayList',
          width: '90%'
        },
        third_goods_id: {
          show: model => model.site_id === 3,
          label: '商品',
          value: undefined,
          formType: 'remote',
          options: '/goods/select?site_id_match=3',
          methods: {
            change: v => {
              this.changeGoods();
            }
          }
        },
        quantity: {
          show: model => model.site_id === 3,
          label: '数量',
          value: undefined,
          formType: 'number',
          attrs: {
            step: 1
          },
          methods: {
            change: v => {
              this.changeGoods();
            }
          }
        },
        addition_id: {
          show: model => model.site_id === 3,
          value: undefined,
          label: '验货拍照',
          formType: 'select',
          options: '/addition/select?position_type_byte=1',
          required: false,
          methods: {
            change: v => {
              this.changeGoods();
            }
          }
        },
        addition_comment: {
          show: model => model.site_id === 3,
          label: '拍照备注',
          value: '',
          required: false,
          methods: {
            change: v => {
              this.changeGoods();
            }
          }
        },
        amount: {
          show: model => model.site_id === 1,
          label: '金额',
          value: undefined,
          formType: 'number',
          attrs: {
            append: '円'
          }
        },
        trans_amount: {
          show: model => model.site_id === 2,
          label: '运费',
          value: undefined,
          formType: 'number',
          attrs: {
            append: '円'
          }
        },
        comment: {
          label: '用户留言',
          value: '',
          formType: 'textarea',
          required: false,
          max: 500,
          attrs: {
            autosize: {
              minRows: 2,
              maxRows: 4
            }
          }
        }
      };
      this.formAction = `${this.module}/add`;
    },
    changeGoods() {
      const model = this.getForm().model;
      if (model.goods.length === 0) {
        model.goods.push({
          third_goods_id: model.third_goods_id,
          quantity: model.quantity,
          site_id: model.site_id,
          addition_id: model.addition_id,
          addition_comment: model.addition_comment
        });
      }
      model.goods[0].quantity = model.quantity;
      model.goods[0].addition_id = model.addition_id;
      model.goods[0].addition_comment = model.addition_comment;
      model.goods[0].site_id = model.site_id;
      model.goods[0].third_goods_id = model.third_goods_id;
    },
    async onSubmit() {
      try {
        this.loading = true;
        const result = await this.$refs.wForm.submit();
        this.loading = false;
        if (result && result.data && result.data.id) {
          this.$router.push({
            name: 'OrderGet',
            query: {
              id: result.data.id
            }
          });
        }
      } catch (e) {
        this.loading = false;
      }
    },
    getForm() {
      return this.$refs.wForm;
    },
    formDone(v) {
      let t = 0,
        r = 0;
      if (v.data && v.data.list) {
        const model = this.getForm().model;
        for (const item of v.data.list) {
          t++;
          if (model.goods.findIndex(i => i.link === item.link) > -1) {
            r++;
            continue;
          }
          model.goods.push(item);
        }
      }
      this.$message.success(`解析${t}条数据，${r}条链接重复`);
    }
  }
};