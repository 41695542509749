var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      module: _vm.module,
      operates: _vm.operates
    },
    on: {
      add: _vm.onAdd
    },
    scopedSlots: _vm._u([{
      key: "attachment",
      fn: function ({
        row
      }) {
        return [row.type === "img" ? _c("el-image", {
          staticStyle: {
            width: "70px",
            height: "70px"
          },
          attrs: {
            src: row.url,
            "preview-src-list": [row.url],
            fit: "contain"
          }
        }) : _c("a", {
          staticClass: "blue",
          attrs: {
            target: "_blank",
            href: row.url
          }
        }, [_vm._v("附件")])];
      }
    }])
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      form: _vm.form,
      title: "上传"
    },
    on: {
      done: _vm.getList
    },
    model: {
      value: _vm.dialogVisible,
      callback: function ($$v) {
        _vm.dialogVisible = $$v;
      },
      expression: "dialogVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };